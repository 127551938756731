//urlUtils.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/urlUtils.js
import { FILTER_TYPES } from "../constants/filterParams";
import { normalizeAddress, isAddress } from "./addressUtils";
/**
 * Updates the URL with the given search parameters and filters.
 * Constructs the URL based solely on the provided filters.
 *
 * @param {Object} updatedFilters - The filters to update or add.
 * @param {string} currentSearchQuery - The current search query (location or address).
 * @param {string} currentSearchType - The type of search ('location', 'polygon', 'address').
 * @param {Array} [polygonCoords=null] - The polygon coordinates, if any.
 * @param {string|null} [zpid=null] - The zpid parameter, if any.
 * @param {Object} [existingFilters={}] - The current filters from state.
 * @returns {string} The updated URL.
 */
export const updateUrlWithSearchParams = (
  updatedFilters,
  currentSearchQuery,
  currentSearchType,
  polygonCoords = null,
  zpid = null,
) => {
  const finalFilters = { ...updatedFilters };

  // Ensure status_type is correctly applied
  const statusType = finalFilters[FILTER_TYPES.STATUS_TYPE] || "ForSale";

  // Handle incompatible filters when switching status types
  if (statusType !== "ForSale") {
    // Remove ForSale-specific filters
    delete finalFilters[FILTER_TYPES.IS_FOR_SALE_FORECLOSURE];
    delete finalFilters[FILTER_TYPES.IS_FORECLOSED];
    delete finalFilters[FILTER_TYPES.IS_PRE_FORECLOSURE];
    delete finalFilters[FILTER_TYPES.IS_PENDING_UNDER_CONTRACT];
    delete finalFilters[FILTER_TYPES.IS_COMING_SOON];
    delete finalFilters[FILTER_TYPES.IS_NEW_CONSTRUCTION];
    delete finalFilters[FILTER_TYPES.IS_AUCTION];
    delete finalFilters[FILTER_TYPES.OTHER_LISTINGS];
  }

  if (statusType !== "ForRent") {
    // Remove ForRent-specific filters
    delete finalFilters[FILTER_TYPES.RENT_MIN_PRICE];
    delete finalFilters[FILTER_TYPES.RENT_MAX_PRICE];
    delete finalFilters[FILTER_TYPES.IS_ENTIRE_PLACE];
    delete finalFilters[FILTER_TYPES.IS_ROOM];
    delete finalFilters[FILTER_TYPES.CATS_ALLOWED];
    delete finalFilters[FILTER_TYPES.SMALL_DOGS_ALLOWED];
    delete finalFilters[FILTER_TYPES.LARGE_DOGS_ALLOWED];
    delete finalFilters[FILTER_TYPES.NO_PETS];
    delete finalFilters[FILTER_TYPES.MOVE_IN_DATE];
    delete finalFilters[FILTER_TYPES.PARKING_AVAILABLE];
    delete finalFilters[FILTER_TYPES.IN_UNIT_LAUNDRY];
    delete finalFilters[FILTER_TYPES.ACCEPTS_APPLICATIONS];
    delete finalFilters[FILTER_TYPES.INCOME_RESTRICTED];
    delete finalFilters[FILTER_TYPES.SHORT_TERM_LEASE];
    delete finalFilters[FILTER_TYPES.FURNISHED];
    delete finalFilters[FILTER_TYPES.OUTDOOR_SPACE];
    delete finalFilters[FILTER_TYPES.CONTROLLED_ACCESS];
    delete finalFilters[FILTER_TYPES.HIGH_SPEED_INTERNET];
    delete finalFilters[FILTER_TYPES.ELEVATOR];
    delete finalFilters[FILTER_TYPES.MULTI_FAMILY_BUILDING];
  }

  if (statusType !== "RecentlySold") {
    // Remove RecentlySold-specific filters
    delete finalFilters[FILTER_TYPES.SOLD_IN_LAST];
  }

  // Handle search type specific parameters
  if (currentSearchType === "polygon") {
    if (polygonCoords && Array.isArray(polygonCoords) && polygonCoords.length > 0) {
      const formattedPolygonCoords = polygonCoords
        .filter(coord => Array.isArray(coord) && coord.length === 2)
        .map(([lng, lat]) => `${lng} ${lat}`)
        .join(",");

      finalFilters[FILTER_TYPES.POLYGON] = formattedPolygonCoords;
    } else {
      console.warn("Invalid or empty polygonCoords received in updateUrlWithSearchParams:", polygonCoords);
      delete finalFilters[FILTER_TYPES.POLYGON];
      delete finalFilters[FILTER_TYPES.LOCATION];
      delete finalFilters[FILTER_TYPES.ADDRESS];
    }
  } else if (currentSearchType === "location") {
    finalFilters[FILTER_TYPES.LOCATION] = currentSearchQuery;
    delete finalFilters[FILTER_TYPES.ADDRESS];
    delete finalFilters[FILTER_TYPES.POLYGON];
  } else if (currentSearchType === "address") {
    const addressStr = typeof currentSearchQuery === "object"
      ? normalizeAddress(currentSearchQuery)
      : currentSearchQuery;
    finalFilters[FILTER_TYPES.ADDRESS] = addressStr;
    delete finalFilters[FILTER_TYPES.LOCATION];
    delete finalFilters[FILTER_TYPES.POLYGON];
  }

  // Remove irrelevant parameters that shouldn't persist in the URL
  delete finalFilters[FILTER_TYPES.ZPID];

  // Generate new search parameters based on finalFilters
  const searchParams = generateFilterParams(finalFilters);

  // Include zpid if valid
  if (zpid && typeof zpid === "string" && zpid.trim() !== "") {
    searchParams.set(FILTER_TYPES.ZPID, zpid);
  }

  return `/listings?${searchParams.toString()}`;
};



/**
 * Generates query parameters for filters, optionally including certain filters even if false.
 * @param {Object} filters - The filters object containing various filter parameters.
 * @param {Array} [alwaysInclude=[]] - List of filter keys to always include, even if false.
 * @returns {URLSearchParams} - The URLSearchParams object with filtered filter parameters.
 */
export const generateFilterParams = (filters, alwaysInclude = []) => {
  const searchParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === 'boolean') {
      // Include boolean filters only if true or if they are in alwaysInclude
      if (value || alwaysInclude.includes(key)) {
        searchParams.set(key, value.toString());
      }
    } else if (typeof value === 'number') {
      // Include numerical filters only if greater than 0
      if (value > 0) {
        searchParams.set(key, value.toString());
      }
    } else if (typeof value === 'string') {
      // Include string filters only if not empty
      if (value.trim() !== '') {
        searchParams.set(key, value);
      }
    } else if (Array.isArray(value)) {
      // Include array filters only if not empty
      if (value.length > 0) {
        searchParams.set(key, value.join(','));
      }
    } else {
      // For other types, include only if not null or undefined
      if (value !== undefined && value !== null) {
        searchParams.set(key, value.toString());
      }
    }
  });

  return searchParams;
};
