//MapContext.js
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/MapContext.js

import React, { createContext, useState, useContext } from 'react';
import { getCachedPropertyDetail } from '../utils/cache.js';

const MapContext = createContext();

// Validate internal application format [lng, lat]
const validateInternalCoordinates = (coords) => {
  if (!Array.isArray(coords)) {
    console.error('Expected an array of coordinate pairs for internal use, but received:', coords);
    return false;
  }
  
  const invalidCoords = coords.filter(coord =>
    !Array.isArray(coord) || coord.length !== 2 || isNaN(coord[0]) || isNaN(coord[1])
  );

  if (invalidCoords.length > 0) {
    console.warn("Invalid coordinates found:", invalidCoords);
    return false;
  }

  return true;
};

const getCachedCoordinates = (zpid) => {
  const cachedDetail = getCachedPropertyDetail[zpid];
  if (cachedDetail && cachedDetail.data.latitude && cachedDetail.data.longitude) {
    return {
      latitude: cachedDetail.data.latitude,
      longitude: cachedDetail.data.longitude
    };
  }
  return null;
};

export const MapProvider = ({ children }) => {
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [searchType, setSearchType] = useState("");  // Initialize searchType to "location"
  const [searchLocationCoordinates, setSearchLocationCoordinates] = useState(null);

  const updatePolygonCoordinates = (coords) => {
    if (!coords || coords.length === 0) {
      // Always clear polygon state and set search type to "location"
      setPolygonCoordinates([]);
      setSearchType("location");
      return;
    }
  
  
    // Proceed with updating the polygon if valid coordinates are given.
    let internalCoords = [];

    if (Array.isArray(coords)) {
      internalCoords = coords;
    } else if (typeof coords === 'string') {
      internalCoords = coords.split(',').map(coordPair => {
        const [lng, lat] = coordPair.trim().split(' ').map(Number);
        if (isNaN(lng) || isNaN(lat)) {
          console.error('Invalid coordinate values found:', coordPair);
          return null;
        }
        return [lng, lat];
      }).filter(coord => coord !== null); // Remove invalid coordinates
    } else {
      console.error('Unsupported format for polygon coordinates:', coords);
      return;
    }
  
    if (validateInternalCoordinates(internalCoords)) {
      // Only proceed if the coordinates are genuinely different.
      const areCoordsDifferent = polygonCoordinates.length !== internalCoords.length ||
        polygonCoordinates.some((coord, index) => coord[0] !== internalCoords[index][0] || coord[1] !== internalCoords[index][1]);

      if (!areCoordsDifferent && searchType === "polygon") {
        console.warn("Polygon coordinates are unchanged and searchType is 'polygon'. Skipping update.");
        return;
      }

      setPolygonCoordinates(internalCoords);
      setSearchType("polygon");
      /* console.log('Polygon coordinates updated in context:', internalCoords, 'Search Type:', searchType); */
    } else {
      console.warn('Invalid polygon coordinates, searchType not set to polygon.');
    }
  };
  

  return (
    <MapContext.Provider value={{ polygonCoordinates, updatePolygonCoordinates, searchType, setSearchType, getCachedCoordinates, searchLocationCoordinates,
      setSearchLocationCoordinates,
    }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
