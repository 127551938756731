//SellDropdown.jsx
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/SellDropdown.jsx
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';

// Lazy load the icon components
const MdLibraryBooks = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.MdLibraryBooks })));
const MdOutlineFormatListBulleted = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.MdOutlineFormatListBulleted })));
const MdOutlineOpenInNew = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.MdOutlineOpenInNew })));
const TbMoneybag = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.TbMoneybag })));
const TbHomeSearch = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.TbHomeSearch })));
const FaBookOpen = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FaBookOpen })));
const FaHandshake = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FaHandshake })));
const FindTopAgents = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FindTopAgents })));

// Store component references in the array
const categories = [
  {
    name: 'All Seller Resources',
    href: '/seller-resources',
    icon: MdLibraryBooks,  // Store component reference, not JSX
  },
  {
    name: 'Get an Instant Offer',
    href: '/list-with-us',
    icon: TbMoneybag,
  },
  {
    name: 'Explore Your Options',
    href: '/seller-resources',
    icon: MdOutlineFormatListBulleted,
  },
  {
    name: 'See Your Home Value',
    href: '/home-value',
    icon: TbHomeSearch,
  },
  {
    name: "Seller's Guide",
    href: '/blog',
    icon: FaBookOpen,
  },
  {
    name: 'Find Top Seller’s Agents',
    href: '/agent-finder',
    icon: FindTopAgents,
  },
  {
    name: 'List with Us',
    href: '/list-with-us',
    icon: MdOutlineOpenInNew,
  },
  {
    name: 'Post For Sale by Owner',
    href: '/contact',
    icon: FaHandshake,
  },
];

function SellDropdown({ closeMobileMenu }) {
    return (
        <Suspense fallback={
  <div
    className="mx-auto w-12 h-12 border-4 border-t-4 border-gray-200 border-t-purple-500 rounded-full animate-spin transition-opacity duration-300 opacity-100"
    role="status"
    aria-label="Loading"
  ></div>
}>
            <div id="sell-dropdown" >
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    {categories.map((category, index) => {
                        const IconComponent = category.icon; // Use the component reference
                        return (
                            <li key={index}>
                                <Link
                                    to={category.href}
                                    onClick={closeMobileMenu} // Ensure menu and dropdown close when link is clicked
                                    className="block flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <IconComponent className="w-5 h-5 text-purple-500" aria-hidden="true" /> {/* Dynamically render the icon */}
                                    <span>{category.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Suspense>
    );
}

export default SellDropdown;
