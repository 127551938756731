import React, { Suspense, useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import { Dropdown } from "flowbite-react";
import { FaUserCircle } from "../../assets/svgIcons/svgIcons.js";
const CgProfile = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.CgProfile })));
const GiTreehouse = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.GiTreehouse })));
const GiUserSettings = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.GiUserSettings })));
const BsFillHouseHeartFill = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.BsFillHouseHeartFill })));
const AiOutlineFieldTime = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AiOutlineFieldTime })));
const RiLogoutCircleLine = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.RiLogoutCircleLine })));
const IoMdLogIn = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.IoMdLogIn })));
const TbHomeRibbon = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.TbHomeRibbon })));
const MdSell = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.MdSell })));
const BsFillChatDotsFill = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.BsFillChatDotsFill })));


const ProfileMenu = ({ setShowProfileMenu, handleLogout, isUserAuthenticated }) => {
  const { user } = useContext(AuthContext);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => setImageError(true);

  const avatarLabel =
    !imageError && user?.photoURL ? (
      <div
        className="w-10 h-10 bg-cover rounded-full"
        style={{ backgroundImage: `url(${user.photoURL})` }}
      >
        <img
          src={user.photoURL}
          className="hidden"
          alt="User Profile"
          onError={handleImageError}
          aria-label="Profile Image Icon"
        />
      </div>
    ) : (
      <FaUserCircle className="w-8 h-8 text-purple-600 dark:text-purple-300 hover:text-purple-400 transition ease-in-out duration-150" />
    );

  const handleLogoutClick = () => {
    handleLogout();
  };

  return (
    <div className="relative">
      <Dropdown
        label={
          isUserAuthenticated ? (
            avatarLabel
          ) : (
            <FaUserCircle
              className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-purple-600 dark:text-purple-200 hover:text-purple-300 dark:hover:text-purple-400 transition ease-in-out duration-150"
              size={32}
              onClick={() => setShowProfileMenu(true)}
              aria-label="User Profile Icon"
            />
          )
        }
        arrowIcon={false}
        inline
        dismissOnClick={false}
        className="absolute left-0 mt-2 w-56 py-2 bg-white dark:bg-slate-800 border border-gray-200 dark:border-slate-700 shadow-lg rounded-md"
        aria-label="Profile Menu"
        data-testid="dropdown-menu"
      >
        <Suspense
          fallback={
            <div
              className="loading-spinner"
              role="status"
              aria-label="Loading"
            ></div>
          }
        >
          {isUserAuthenticated ? (
            <>
              {/* Profile Header */}
              <Dropdown.Header className="flex flex-col gap-1 px-4 py-3 border-b dark:border-slate-700">
                {user?.userName ? (
                  <span className="text-lg font-semibold text-gray-800 dark:text-gray-200 capitalize">
                    {user.userName}
                  </span>
                ) : (
                  <Link
                    to="/dashboard/profilesettings"
                    className="text-sm font-light text-purple-300 hover:text-purple-500 transition ease-in-out duration-150"
                    aria-label="Choose Your Username"
                  >
                    Choose your username
                  </Link>
                )}
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {user?.email}
                </span>
              </Dropdown.Header>

              {/* Menu Items */}
              <Dropdown.Item
                as={Link}
                to="/featured-listings"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Featured Listings"
              >
                <TbHomeRibbon className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Featured Listings
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/dashboard/profilesettings"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Account Settings"
              >
                <GiUserSettings className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Account Settings
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/dashboard/SavedHomes"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Saved Homes"
              >
                <BsFillHouseHeartFill className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Saved Homes
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/dashboard/RecentlyViewed"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Recents"
              >
                <AiOutlineFieldTime className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Recents
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/contact"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Contact Us"
              >
                <BsFillChatDotsFill className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Contact Us
              </Dropdown.Item>

              {/* Logout Button */}
              <Dropdown.Item
                as="button"
                onClick={handleLogoutClick}
                className="flex items-center gap-3 px-4 py-2 text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700/50 rounded-md transition-all duration-200"
                aria-label="Log Out"
              >
                <RiLogoutCircleLine className="w-5 h-5 text-red-600 dark:text-red-400" />
                Log Out
              </Dropdown.Item>
            </>
          ) : (
            <>
              <Dropdown.Item
                as={Link}
                to="?auth=login"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Login"
              >
                <IoMdLogIn className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Login
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/featured-listings"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Featured Listings"
              >
                <TbHomeRibbon className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Featured Listings
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/buyer-resources"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Buyer Resources"
              >
                <GiTreehouse className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Buyer Resources
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/seller-resources"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Seller Resources"
              >
                <MdSell className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Seller Resources
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/contact"
                className="flex items-center gap-3 px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-purple-100 dark:hover:bg-purple-700/50 rounded-md transition-all duration-200"
                aria-label="Contact Us"
              >
                <BsFillChatDotsFill className="w-5 h-5 text-purple-600 dark:text-purple-300" />
                Contact Us
              </Dropdown.Item>
            </>
          )}
        </Suspense>
      </Dropdown>
    </div>
  );
};

export default ProfileMenu;
