// fetchAdditionalDetailsByAddress.jsx
import axios from "axios";
import Swal from "sweetalert2";
import { normalizeAddress } from "../utils/addressUtils.js";
import {
  setCachedPropertyDetail,
  getCachedPropertyDetail,
  normalizeKey
} from "../utils/cache.js";

export const fetchAdditionalDetailsByAddress = async (address) => {
  console.log("fetchAdditionalDetailsByAddress called with:", address);

  if (!address || typeof address !== "string") {
    throw new Error("Address is required to fetch details.");
  }

  // 1) Local React cache
  const normalizedAddress = normalizeAddress(address);
  let cachedDetail = getCachedPropertyDetail(normalizedAddress);
  if (cachedDetail) {
    console.log("Local cache hit for address:", normalizedAddress);
    return cachedDetail;
  }

  console.log("Local cache miss for address:", normalizedAddress);

  // 2) Decide endpoint based on environment
  const isProduction = process.env.NODE_ENV === "production";
  let apiUrl = "";
  let requestConfig = {};

  if (isProduction) {
    // Production: call your Worker-based serverless endpoint
    if (window.location.hostname.includes("vercel.app")) {
      apiUrl = `https://${window.location.hostname}/api/propertyDetails`;
    } else {
      apiUrl = "https://www.spearrealestategroup.com/api/propertyDetails";
    }
    requestConfig = {
      method: "GET",
      url: apiUrl,
      params: { address: normalizedAddress },
      headers: {
        "Content-Type": "application/json"
      }
    };
  } else {
    // Development: call Zillow via RapidAPI
    const host = "zillow-com1.p.rapidapi.com";
    const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;
    apiUrl = `https://${host}/property`;
    requestConfig = {
      method: "GET",
      url: apiUrl,
      params: { address: normalizedAddress },
      headers: {
        "x-rapidapi-key": key,
        "x-rapidapi-host": host
      }
    };
  }

  try {
    console.log(`Fetching details for address: ${normalizedAddress} from ${apiUrl}`);
    const response = await axios.request(requestConfig);
    const data = response.data;

    if (!data || !data.zpid || !data.address) {
      console.error("Invalid or incomplete data structure:", data);
      return null;
    }

    // (Optional) Check Worker’s X-Cache
    const xCacheHeader = response.headers["x-cache"];
    if (xCacheHeader === "HIT") {
      console.log("Momento Worker Cache HIT for address:", normalizedAddress);
    }

    // 3) Cache in local
    const zpidKey = normalizeKey(data.zpid);
    setCachedPropertyDetail(zpidKey, data);
    setCachedPropertyDetail(normalizedAddress, data);

    return data;
  } catch (error) {
    console.error(`Error fetching details for address ${address}:`, error);
    return null;
  }
};
