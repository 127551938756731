//filterParams.js
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/constants/filterParams.js

//filterParams.js
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/constants/filterParams.js

// FILTER_TYPES groups all filter keys.
// The comments indicate which section of the RapidAPI docs they correspond to.
export const FILTER_TYPES = {
  // --- Location & Pagination ---
  LOCATION: 'location',               // Query: location details (address, county, neighborhood, or Zip code)
  PAGE: 'page',                       // Query: page number (max value is 20)

  // --- Price Filters ---
  MIN_PRICE: 'minPrice',              // ForSale/RecentlySold: Minimum Price
  MAX_PRICE: 'maxPrice',              // ForSale/RecentlySold: Maximum Price
  RENT_MIN_PRICE: 'rentMinPrice',     // ForRent: Minimum Rent Price
  RENT_MAX_PRICE: 'rentMaxPrice',     // ForRent: Maximum Rent Price

  // --- Bedroom & Bathroom Filters ---
  BEDS_MIN: 'bedsMin',                // Minimum bedrooms count
  BEDS_MAX: 'bedsMax',                // Maximum bedrooms count
  BATHS_MIN: 'bathsMin',              // Minimum bathrooms count
  BATHS_MAX: 'bathsMax',              // Maximum bathrooms count

  // --- Area & Build Filters ---
  SQFT_MIN: 'sqftMin',                // Minimum square feet
  SQFT_MAX: 'sqftMax',                // Maximum square feet
  BUILD_YEAR_MIN: 'buildYearMin',     // Minimum year built
  BUILD_YEAR_MAX: 'buildYearMax',     // Maximum year built

  // --- Lot Size Filters ---
  LOT_SIZE_MIN: 'lotSizeMin',         // Minimum lot size (e.g., "1,000 sqft" or "1/4 acre/10,890 sqft")
  LOT_SIZE_MAX: 'lotSizeMax',         // Maximum lot size

  // --- Date Filters ---
  DAYS_ON: 'daysOn',                  // Days on Zillow (values: 1,7,14,30,90,6m,12m,24m,36m)
  SOLD_IN_LAST: 'soldInLast',          // 'Sold In Last' filter for RecentlySold listings

  // --- Property Type & Status ---
  STATUS_TYPE: 'status_type',         // Enum for status (ForSale, RecentlySold, ForRent)
  HOME_TYPE: 'home_type',             // Property types (Houses, Townhomes, Apartments, etc.)
  SORT: 'sort',                       // Sorting options per status (e.g., Homes_for_You, Price_High_Low, Verified_Source)

  // --- Sale Specific Filters ---
  IS_OPEN_HOUSES_ONLY: 'isOpenHousesOnly',       // Boolean: Must have open house
  IS_COMING_SOON: 'isComingSoon',                // Boolean: Coming soon listings
  IS_PENDING_UNDER_CONTRACT: 'isPendingUnderContract', // Boolean/Number: Pending & Under Contract
  IS_FOR_SALE_FORECLOSURE: 'isForSaleForeclosure', // Boolean: ForSaleForeclosure filter
  IS_FORECLOSURE: 'isForeclosed',
  IS_FORECLOSED: 'isForeclosed',                // Boolean: Foreclosed property filter
  IS_PRE_FORECLOSURE: 'isPreForeclosure',         // Boolean: PreForeclosure filter
  SALE_BY_OWNER: 'saleByOwner',                  // String: FSBO (For Sale By Owner)
  SALE_BY_AGENT: 'saleByAgent',                  // String: Sale by Agent filter

  // --- View & Feature Filters ---
  IS_PARK_VIEW: 'isParkView',        // Boolean: Park view filter
  IS_WATER_VIEW: 'isWaterView',      // Boolean: Water view filter
  IS_WATERFRONT: 'isWaterfront',       // Boolean: Waterfront filter
  IS_CITY_VIEW: 'isCityView',        // Boolean: City view filter
  IS_MOUNTAIN_VIEW: 'isMountainView',// Boolean: Mountain view filter
  IS_NEW_CONSTRUCTION: 'isNewConstruction', // Boolean: New Construction filter
  OTHER_LISTINGS: 'otherListings',   // Boolean: Only include Other Listings tab data
  IS_ACCEPTING_BACKUP_OFFERS: 'isAcceptingBackupOffers', // Boolean: Accepting backup offers

  // --- Keyword & Basement Filters ---
  KEYWORDS: 'keywords',              // String: Keyword search filter
  IS_BASEMENT_FINISHED: 'isBasementFinished', // Number: Finished basement (set to 1 if needed)
  IS_BASEMENT_UNFINISHED: 'isBasementUnfinished', // Number: Unfinished basement (set to 1 if needed)

  // --- Additional Property Features ---
  HAS_POOL: 'hasPool',               // Boolean: Has pool filter
  HAS_AIR_CONDITIONING: 'hasAirConditioning', // Boolean: Air conditioning filter
  IS_3D_HOME: 'is3dHome',            // Boolean: 3D Tour availability

  // --- Geolocation Filters ---
  COORDINATES: 'coordinates',        // String: Coordinates filter (required if location or polygon is empty)
  POLYGON: 'polygon',                // String: Polygon filter (format: lon lat,lon1 lat1,lon2 lat2; last pair must equal first)
  ADDRESS: 'address',                // String: Specific address filter

  // --- HOA Filters ---
  HOA: 'hoa',                        // Number: Maximum HOA fee filter
  INCLUDE_HOMES_WITH_NO_HOA_DATA: 'includeHomesWithNoHoaData', // Boolean: Include homes without HOA data

  // --- Auction & School Filters ---
  IS_AUCTION: 'isAuction',           // Boolean: Auction filter (default true)
  SCHOOLS: 'schools',                // String: School types filter (e.g., elementary, public, private)
  SCHOOLS_RATING: 'schoolsRating',   // String: Minimum school rating (1 to 10)
  INCLUDE_UNRATED_SCHOOLS: 'includeUnratedSchools', // Boolean: Include schools with no rating

  // --- Garage & Parking Filters ---
  HAS_GARAGE: 'hasGarage',           // Boolean: Must have a garage
  PARKING_SPOTS: 'parkingSpots',     // Number: Number of parking spots (max 4)

  // --- Rental Specific Filters (ForRent) ---
  IS_ENTIRE_PLACE: 'isEntirePlace',  // Boolean: Entire place filter (default true for ForRent)
  IS_ROOM: 'isRoom',                // Boolean: Room filter (set true and isEntirePlace false if only a room is needed)

  // --- Additional Rental Filters ---
  LARGE_DOGS_ALLOWED: 'largeDogsAllowed',    // Boolean: Allows large dogs
  SMALL_DOGS_ALLOWED: 'smallDogsAllowed',    // Boolean: Allows small dogs
  CATS_ALLOWED: 'catsAllowed',               // Boolean: Allows cats
  NO_PETS: 'noPets',                         // Boolean: No pets allowed
  MOVE_IN_DATE: 'moveInDate',                // Date (yyyy-mm-dd): Move-in date filter
  PARKING_AVAILABLE: 'parkingAvailable',     // Boolean: On-site parking filter
  IN_UNIT_LAUNDRY: 'inUnitLaundry',          // Boolean: In-unit laundry filter
  ACCEPTS_APPLICATIONS: 'acceptsApplications', // Boolean: Accepts Zillow applications
  INCOME_RESTRICTED: 'incomeRestricted',     // Boolean: Income restricted filter
  HARDWOOD_FLOOR: 'hardwoodFloor',           // Boolean: Hardwood floors filter
  DISABLED_ACCESS: 'disabledAccess',         // Boolean: Disabled access filter
  UTILITIES_INCLUDED: 'utilitiesIncluded',   // Boolean: Utilities included filter
  SHORT_TERM_LEASE: 'shortTermLease',         // Boolean: Short term lease available
  FURNISHED: 'furnished',                    // Boolean: Furnished filter
  OUTDOOR_SPACE: 'outdoorSpace',             // Boolean: Outdoor space filter
  CONTROLLED_ACCESS: 'controlledAccess',     // Boolean: Controlled access filter
  HIGH_SPEED_INTERNET: 'highSpeedInternet',   // Boolean: High speed internet filter
  ELEVATOR: 'elevator',                      // Boolean: Elevator filter
  MULTI_FAMILY_BUILDING: 'multiFamilyBuilding' // Boolean: Apartment community filter
};

// ALL_FILTER_PARAMS defines the order of filters to be sent in API requests.
export const ALL_FILTER_PARAMS = [
  // --- Common Filters ---
  'location',        // Location details (address, zip, etc.)
  'page',            // Page number
  'minPrice',        // Minimum Price (ForSale/RecentlySold)
  'maxPrice',        // Maximum Price (ForSale/RecentlySold)
  'rentMinPrice',    // Minimum Rent Price (ForRent)
  'rentMaxPrice',    // Maximum Rent Price (ForRent)
  'bedsMin',         // Minimum bedrooms
  'bedsMax',         // Maximum bedrooms
  'bathsMin',        // Minimum bathrooms
  'bathsMax',        // Maximum bathrooms
  'sqftMin',         // Minimum square feet
  'sqftMax',         // Maximum square feet
  'buildYearMin',    // Minimum year built
  'buildYearMax',    // Maximum year built
  'lotSizeMin',      // Minimum lot size
  'lotSizeMax',      // Maximum lot size
  'daysOn',          // Days on Zillow
  'soldInLast',      // Sold in last timeframe
  'status_type',     // Status type (ForSale, RecentlySold, ForRent)
  'home_type',       // Home type (e.g., Houses, Townhomes)
  'sort',            // Sorting options
  'isOpenHousesOnly',// Only open houses
  'isComingSoon',    // Coming soon listings
  'isPendingUnderContract', // Pending/Under Contract
  'isForSaleForeclosure',   // For Sale Foreclosure
  'isForeclosed',    // Foreclosed properties
  'isPreForeclosure',// Pre-foreclosure filter
  'saleByOwner',     // Sale by Owner (FSBO)
  'saleByAgent',     // Sale by Agent filter
  'isParkView',      // Park view
  'isWaterView',     // Water view
  'isWaterfront',    // Waterfront
  'isCityView',      // City view
  'isMountainView',  // Mountain view
  'isNewConstruction', // New construction filter
  'otherListings',   // Other listings tab
  'isAcceptingBackupOffers', // Accepting backup offers
  'keywords',        // Keyword filter
  'isBasementFinished', // Finished basement
  'isBasementUnfinished', // Unfinished basement
  'hasPool',         // Has pool
  'hasAirConditioning', // Air conditioning
  'is3dHome',        // 3D Tour availability
  'coordinates',     // Coordinates (lon lat,diameter)
  'polygon',         // Polygon filter
  'address',         // Address filter
  'hoa',             // HOA filter
  'includeHomesWithNoHoaData', // Include homes without HOA data
  'isAuction',       // Auction filter
  'schools',         // School types filter
  'schoolsRating',   // Minimum school rating
  'includeUnratedSchools', // Include unrated schools
  'hasGarage',       // Garage filter
  'parkingSpots',    // Parking spots filter
  'isEntirePlace',   // Entire place filter (ForRent)
  'isRoom',          // Room filter (ForRent)

  // --- Rental Specific Filters (ForRent only) ---
  'largeDogsAllowed',  // Allows large dogs
  'smallDogsAllowed',  // Allows small dogs
  'catsAllowed',       // Allows cats
  'noPets',            // No pets allowed
  'moveInDate',        // Move-in date
  'parkingAvailable',  // On-site parking
  'inUnitLaundry',     // In-unit laundry
  'acceptsApplications', // Accepts Zillow applications
  'incomeRestricted',  // Income restricted
  'hardwoodFloor',     // Hardwood floors
  'disabledAccess',    // Disabled access
  'utilitiesIncluded', // Utilities included
  'shortTermLease',    // Short term lease available
  'furnished',         // Furnished
  'outdoorSpace',      // Outdoor space
  'controlledAccess',  // Controlled access
  'highSpeedInternet', // High speed internet
  'elevator',          // Elevator
  'multiFamilyBuilding' // Apartment community filter
];
