// BuyDropdown.jsx
import React, { Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Lazy load the icons
const AllBuyerResources = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AllBuyerResources })));
const AllHomesForSale = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AllHomesForSale })));
const Foreclosures = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.Foreclosures })));
const FSBO = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FSBO })));
const OpenHouses = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.OpenHouses })));
const NewConstruction = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.NewConstruction })));
const RecentHomeSale = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.RecentHomeSale })));
const FindTopAgents = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FindTopAgents })));
const FTHB = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FTHB })));
const ComingSoon = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.ComingSoon })));

// Store component references in the categories array
const categories = [
    {
        name: 'All Buyer Resources',
        href: '/buyer-resources',
        icon: AllBuyerResources,
    },
    {
        name: 'All Homes for Sale',
        href: '/listings',
        icon: AllHomesForSale,
    },
    {
        name: 'Foreclosures',
        href: '/listings?page=1&isForSaleForeclosure=true',
        icon: Foreclosures,
    },
    {
        name: 'For Sale by Owner',
        href: '/listings?saleByOwner=true',
        icon: FSBO,
    },
    {
        name: 'Open Houses',
        href: '/listings?isOpenHouseOnly=1',
        icon: OpenHouses,
    },
    {
        name: 'New Construction',
        href: '/listings?buildYearMin=2024',
        icon: NewConstruction,
    },
    {
        name: 'Just Listed',
        href: '/listings?location=oregon+usa&daysOn=1&sort=newest&page=1',
        icon: ComingSoon,
    },
    {
        name: 'Recent Home Sales',
        href: '/listings?status_type=RecentlySold',
        icon: RecentHomeSale,
    },
    {
        name: 'Find Top Agents',
        href: '/agent-finder',
        icon: FindTopAgents,
    },
    {
        name: 'First Time Buyer Guide',
        href: 'https://prezi.com/i/view/Smr7Mc5R7bmp3L0DQQIb/',
        icon: FTHB,
    },
];

function BuyDropdown({ closeMobileMenu }) {

    return (
        <Suspense fallback={
            <div
                className="mx-auto w-12 h-12 border-4 border-t-4 border-gray-200 border-t-purple-500 rounded-full animate-spin transition-opacity duration-300 opacity-100"
                role="status"
                aria-label="Loading"
            ></div>
        }>
            <div id="buy-dropdown" className="">
            <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                >
                    {categories.map((category, index) => {
                        const IconComponent = category.icon; // Use the component reference
                        return (
                            <li key={index} role="none">
                                <Link
                                    to={category.href}
                                    onClick={closeMobileMenu} // Ensure menu and dropdown close when link is clicked
                                    className="block flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                    role="menuitem"
                                    aria-label={category.name}
                                >
                                    <IconComponent
                                        className="w-5 h-5 text-purple-500"
                                        aria-hidden="true"
                                    />
                                    <span>{category.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Suspense>
    );
}

export default BuyDropdown;
