//SEO.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/seo/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
  title, 
  description, 
  canonical, 
  ogImage, 
  twitterCard = "summary_large_image", 
  keywords,
  author = "Spear Real Estate Group",
  robots = "index, follow",
  locale = "en_US"
}) => (
  <Helmet>
    {/* Primary Meta Tags */}
    {title && <title>{title}</title>}
    {description && <meta name="description" content={description} />}
    {keywords && <meta name="keywords" content={keywords} />}
    {canonical && <link rel="canonical" href={canonical} />}
    {robots && <meta name="robots" content={robots} />}
    
    {/* Open Graph / Facebook */}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {canonical && <meta property="og:url" content={canonical} />}
    {ogImage && <meta property="og:image" content={ogImage} />}
    {ogImage && <meta property="og:image:width" content="1200" />}
    {ogImage && <meta property="og:image:height" content="630" />}
    {author && <meta property="og:site_name" content={author} />}
    {locale && <meta property="og:locale" content={locale} />}
    <meta property="og:type" content="article" />
    
    {/* Twitter */}
    {twitterCard && <meta name="twitter:card" content={twitterCard} />}
    {canonical && <meta name="twitter:url" content={canonical} />}
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {ogImage && <meta name="twitter:image" content={ogImage} />}

    {/* Mobile Optimization */}
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

{/* Google Search Console Verification */}
<meta name="google-site-verification" content="VUu1Zrk0RjQoKbk7uZyZPh2gnynvGNqegI_UNiX-6sU" />

    {/* Structured Data Placeholder for JSON-LD (can be added dynamically per page) */}
  </Helmet>
);

export default SEO;
