// cache.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/cache.js

// Cache keys and initializations
export const CACHE_KEY = 'propertyDetailCache';
export const SIMILAR_PROPERTIES_CACHE_KEY = 'similarPropertiesCache';
export const IMAGE_CACHE_KEY = 'imageCache';
export const POLYGON_SEARCH_CACHE_KEY = 'polygonSearchCache';

export let propertyDetailCache = JSON.parse(localStorage.getItem(CACHE_KEY)) || {};
export let similarPropertiesCache = JSON.parse(localStorage.getItem(SIMILAR_PROPERTIES_CACHE_KEY)) || {};
export let imageCache = JSON.parse(localStorage.getItem(IMAGE_CACHE_KEY)) || {};
export let polygonSearchCache = JSON.parse(localStorage.getItem(POLYGON_SEARCH_CACHE_KEY)) || {};

// Cache settings
export const cacheDuration = 7 * 24 * 60 * 60 * 1000; // 1 week
export const cacheTimeout = 3000000; // 50 minutes
const MAX_CACHE_ENTRIES = 50; // Optional: max size

// Safe setter for localStorage
export const safeSetLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(`⚠️ Cache quota exceeded for key: ${key}. Clearing that cache.`);
    localStorage.removeItem(key);
  }
};

// Optional cache trimmer
const trimCache = (cache) => {
  const keys = Object.keys(cache);
  if (keys.length > MAX_CACHE_ENTRIES) {
    keys
      .sort((a, b) => cache[a].timestamp - cache[b].timestamp)
      .slice(0, keys.length - MAX_CACHE_ENTRIES)
      .forEach(key => delete cache[key]);
  }
};

// Clear all
export const clearCache = () => {
  propertyDetailCache = {};
  similarPropertiesCache = {};
  imageCache = {};
  polygonSearchCache = {};
  localStorage.removeItem(CACHE_KEY);
  localStorage.removeItem(SIMILAR_PROPERTIES_CACHE_KEY);
  localStorage.removeItem(IMAGE_CACHE_KEY);
  localStorage.removeItem(POLYGON_SEARCH_CACHE_KEY);
};

// Normalization
export const normalizeKey = (key) => String(key).toLowerCase().trim();
export const normalizeAddressKey = (address) =>
  `${address.streetAddress} ${address.city} ${address.state} ${address.zipcode} usa`.toLowerCase().trim();

// Expiry checker
export const isCacheExpired = (expiry) => Date.now() > expiry;

// Property Detail Cache
export const setCachedPropertyDetail = (key, data) => {
  key = normalizeKey(key);
  propertyDetailCache[key] = { data, timestamp: Date.now() };

  if (data.zpid) {
    const zpidKey = normalizeKey(data.zpid);
    propertyDetailCache[zpidKey] = { data, timestamp: Date.now() };
  }

  trimCache(propertyDetailCache);
  safeSetLocalStorage(CACHE_KEY, propertyDetailCache);
};

export const getCachedPropertyDetail = (key) => {
  key = normalizeKey(key);
  const cached = propertyDetailCache[key];
  if (cached) {
    if (!isCacheExpired(cached.timestamp + cacheTimeout)) {
      return cached.data;
    } else {
      delete propertyDetailCache[key];
      safeSetLocalStorage(CACHE_KEY, propertyDetailCache);
    }
  }
  return null;
};

// Polygon Cache
export const generateCacheKey = (polygonCoords, effectiveFilters) => {
  if (!polygonCoords || !effectiveFilters) return null;
  const relevantFilters = {
    sort: effectiveFilters.sort,
    status_type: effectiveFilters.status_type,
    minPrice: effectiveFilters.minPrice,
    maxPrice: effectiveFilters.maxPrice,
    bedsMin: effectiveFilters.bedsMin,
    bedsMax: effectiveFilters.bedsMax,
    bathsMin: effectiveFilters.bathsMin,
    bathsMax: effectiveFilters.bathsMax,
  };
  const serialized = Object.keys(relevantFilters)
    .sort()
    .map(k => `${k}:${relevantFilters[k]}`)
    .join('|');
  return `${polygonCoords}|${serialized}`;
};

export const setCachedPolygonSearch = (polygonCoords, effectiveFilters, data) => {
  const key = generateCacheKey(polygonCoords, effectiveFilters);
  if (!key) return;
  polygonSearchCache[key] = { data, timestamp: Date.now() };
  trimCache(polygonSearchCache);
  safeSetLocalStorage(POLYGON_SEARCH_CACHE_KEY, polygonSearchCache);
};

export const getCachedPolygonSearch = (polygonCoords, effectiveFilters) => {
  const key = generateCacheKey(polygonCoords, effectiveFilters);
  if (!key) return null;
  const cached = polygonSearchCache[key];
  if (cached) {
    if (!isCacheExpired(cached.timestamp + cacheTimeout)) {
      return cached.data;
    } else {
      delete polygonSearchCache[key];
      safeSetLocalStorage(POLYGON_SEARCH_CACHE_KEY, polygonSearchCache);
    }
  }
  return null;
};

// Similar Properties Cache
export const setCachedSimilarProperties = (key, data) => {
  key = normalizeKey(key);
  similarPropertiesCache[key] = { data, timestamp: Date.now() };
  trimCache(similarPropertiesCache);
  safeSetLocalStorage(SIMILAR_PROPERTIES_CACHE_KEY, similarPropertiesCache);
};

export const getCachedSimilarProperties = (key) => {
  key = normalizeKey(key);
  const cached = similarPropertiesCache[key];
  if (cached) {
    if (!isCacheExpired(cached.timestamp + cacheTimeout)) {
      return cached.data;
    } else {
      delete similarPropertiesCache[key];
      safeSetLocalStorage(SIMILAR_PROPERTIES_CACHE_KEY, similarPropertiesCache);
    }
  }
  return null;
};

// Image Cache
export const setCachedImages = (key, data) => {
  key = normalizeKey(key);
  imageCache[key] = { data, timestamp: Date.now() };
  trimCache(imageCache);
  safeSetLocalStorage(IMAGE_CACHE_KEY, imageCache);
};

export const getCachedImages = (key) => {
  key = normalizeKey(key);
  const cached = imageCache[key];
  if (cached) {
    if (!isCacheExpired(cached.timestamp + cacheTimeout)) {
      return cached.data;
    } else {
      delete imageCache[key];
      safeSetLocalStorage(IMAGE_CACHE_KEY, imageCache);
    }
  }
  return null;
};
