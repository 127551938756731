/* global hj */

// **React and Related Imports**
import React, { Suspense, lazy, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import SEO from "./seo/SEO.js";
// **Utility and Context Imports**
import axios from "axios";
import Swal from "sweetalert2";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useMapContext } from "./contexts/MapContext.js";
import { fetchAdditionalDetailsByAddress } from "./FetchFunctions/fetchAdditionalDetailsByAddress.jsx";
import { fetchAdditionalDetails } from "./FetchFunctions/fetchAdditionalDetails.jsx";
import { getUserLocation } from "./utils/getUserLocation.js";
import { ScrollLockProvider } from "./contexts/scrollLockContext.js";
import { getCachedPolygonSearch, setCachedPolygonSearch, generateCacheKey } from "./utils/cache.js";
import { updateUrlWithSearchParams } from "./utils/urlUtils.js";
import { ALL_FILTER_PARAMS, FILTER_TYPES } from "./constants/filterParams.js";
// **Non-Lazy-Loaded Components**
import Navbar from "./components/navbar/NavBar.jsx";
import AuthModal from "./components/Login/AuthModal.jsx";
import { ErrorProvider } from "./ErrorProvider.js";
import { TutorialProvider } from "./contexts/TutorialContext.js";
import { ViewModeProvider } from "./contexts/ViewModeContext.js";
import { app } from './components/firebase/Firebase.js';
import { isSupported, getAnalytics } from 'firebase/analytics';
import ErrorBoundary from "./utils/ErrorBoundry.jsx";
import ProtectedRoute from "./routes/ProtectedRoute.jsx";
import { isAddress, normalizeAddress } from "./utils/addressUtils.js";
import NotFound from "./components/pages/NotFound.jsx";
import { Analytics } from '@vercel/analytics/react';


// **Lazy-Loaded Components**
const Home = lazy(() => import('./routes/Home.jsx'));
const LoadingSpinner = lazy(() => import("./components/loading/LoadingSpinner.jsx"));
const Listings = lazy(() => import('./routes/Listings.jsx'));
const Dashboard = lazy(() => import('./components/Login/Dashboard/Dashboard.jsx'));
const ProfileSettings = lazy(() => import('./components/Login/Dashboard/ProfileSettings.jsx'));
const PrivacyPolicy = lazy(() => import('./components/pages/companyResources/PrivacyPolicy.jsx'));
const Contact = lazy(() => import('./components/pages/companyResources/Contact.jsx'));
const AboutUs = lazy(() => import('./components/pages/companyResources/AboutUs.jsx'));
const SearchMortgageRates = lazy(() => import('./components/mortgageSection/SearchMortgageRates.jsx'));
const ListWithUs = lazy(() => import('./components/pages/sellerResources/ListWithUs.jsx'));
const HomeValue = lazy(() => import('./components/pages/sellerResources/HomeValue.jsx'));
const MortgageCalculator = lazy(() => import('./components/mortgageSection/MortgageCalculator.jsx'));
const RefinancePage = lazy(() => import('./components/mortgageSection/RefinancePage.jsx'));
const InvestmentCalculator = lazy(() => import('./components/pages/investorResources/InvestmentCalculator.jsx'));
const RenterResources = lazy(() => import('./components/pages/renterResources/RenterResources.jsx'));
const BuyerResources = lazy(() => import('./components/pages/buyerResources/BuyerResources.jsx'));
const SellerResources = lazy(() => import('./components/pages/sellerResources/SellerResources.jsx'));
const InvestorResources = lazy(() => import('./components/pages/investorResources/InvestorResources.jsx'));
const AgentFinderForm = lazy(() => import('./components/pages/companyResources/AgentFinderForm.jsx'));
const AgentFinderLanding = lazy(() => import('./components/pages/companyResources/AgentFinderLanding.jsx'));
const BookingPage = lazy(() => import('./components/pages/companyResources/BookingPage.jsx'));
const GetMoving = lazy(() => import('./components/pages/companyResources/GetMoving.jsx'));
const ResourcesLanding = lazy(() => import('./components/pages/ResourcesLanding.jsx'));
const Reset = lazy(() => import('./components/Login/Reset.js'));
const BlogList = lazy(() => import('./components/pages/blogs/BlogList.jsx'));
const BlogPostDetail = lazy(() => import('./components/pages/blogs/BlogPostDetail.jsx'));
const FeaturedListings = lazy(() => import('./components/navbar/FeaturedListings.jsx'));
const SearchLenders = lazy(() => import('./components/mortgageSection/SearchLenders.jsx'));
const RentalMarketTrends = lazy(() => import('./components/pages/investorResources/RentalMarketTrends.jsx'));
const SiteFooter = lazy(() => import('./components/footer/SiteFooter.jsx'));
const SitemapPage = lazy(() => import("./components/pages/companyResources/SitemapPage.jsx"));
const TermsOfService = lazy(() => import("./components/pages/companyResources/TermsOfService.jsx"));
const GoogleAuthCallback = lazy(() => import('./components/Login/GoogleAuthCallback.jsx'));

function App({ initialLoadingDone, startProgress, completeProgress, updateProgress, loading, progress }) {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [selectedZpid, setSelectedZpid] = useState(null);
  const [listings, setListings] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [urlTriggeredUpdate, setUrlTriggeredUpdate] = useState(false);

  const fetchTriggeredRef = useRef(false);
  const lastFetchedSearchRef = useRef(null);
  const [searchSource, setSearchSource] = useState(null); // 'urlLoad' or 'user'
  const abortControllerRef = useRef(null);
  const prevSearchTypeRef = useRef();
  

  const [searchCounter, setSearchCounter] = useState(0);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [cachedPropertyDetails, setCachedPropertyDetails] = useState(null);

  const [shouldOpenModalOnLoad, setShouldOpenModalOnLoad] = useState(false);

  const { polygonCoordinates, updatePolygonCoordinates, searchType, setSearchType } = useMapContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const zpid = queryParams.get('zpid');
  const address = queryParams.get('address');
  const addressTriggered = queryParams.get('addressTriggered');

  // For page changes, we now push a new entry
  const onPageChange = (newPage) => {
    const updatedFilters = { ...filters, [FILTER_TYPES.PAGE]: newPage.toString() };
    setFilters(updatedFilters);
    const newUrl = updateUrlWithSearchParams(
      updatedFilters,
      useQuery,
      searchType,
      polygonCoordinates ? polygonCoordinates : null,
      null,
      defaultFilters
    );
    navigate(newUrl); // Pushing history
    setUrlTriggeredUpdate(true);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingNew, setIsFetchingNew] = useState(false);
  const [filters, setFilters] = useState({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "Newest",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isForeclosed: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page: "1",
  });
  const filtersRef = useRef(filters);
  const defaultFilters = useMemo(
    () => ({
      minPrice: "",
      maxPrice: "",
      bedsMin: "",
      bedsMax: "",
      bathsMin: "",
      bathsMax: "",
      sqftMin: "",
      sqftMax: "",
      buildYearMin: "",
      buildYearMax: "",
      lotSizeMin: "",
      lotSizeMax: "",
      daysOn: "",
      daysOnZillow: "",
      status_type: "",
      home_type: "",
      sort: "Newest",
      isOpenHousesOnly: "",
      isComingSoon: "",
      isPendingUnderContract: "",
      isForSaleForeclosure: "",
      isForeclosed: "",
      isPreForeclosure: "",
      saleByOwner: "",
      saleByAgent: "",
      isParkView: "",
      isWaterView: "",
      isWaterfront: "",
      isCityView: "",
      isMountainView: "",
      page: "1",
    }),
    []
  );

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const [useQuery, setUseQuery] = useState("");
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [activeTab] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [resetData, setResetData] = useState(false);
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const currentPage = useMemo(() => parseInt(filters.page || "1", 10), [filters.page]);
  const [selectedListing, setSelectedListing] = useState(null);

  useEffect(() => {
    const authParam = queryParams.get('auth');
    if (authParam === 'login' || authParam === 'register') {
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
    }
  }, [location, queryParams]);

  const closeAuthModal = useCallback(() => {
    setShowAuthModal(false);
    setTimeout(() => {
      const params = new URLSearchParams(location.search);
      params.delete('auth');
      let newUrl = `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
      if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/dashboard') {
        navigate('/', { replace: true });
      } else {
        navigate(newUrl, { replace: true });
      }
    }, 0);
  }, [location, navigate]);

  const [currentLocationSearch, setCurrentLocationSearch] = useState(location.search);
  const [isProduction] = useState(process.env.NODE_ENV === 'production');
  const host = "zillow-com1.p.rapidapi.com";
  const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;

  const apiConfig = {
    testing: {
      apiUrl: "https://zillow-com1.p.rapidapi.com/propertyExtendedSearch",
      headers: {
        "x-rapidapi-key": process.env.REACT_APP_API_KEY_RAPID_API_KEY,
        "x-rapidapi-host": "zillow-com1.p.rapidapi.com",
      },
    },
    production: {
      apiUrl: "/api/extendedSearch",
      headers: {},
    },
  };
  const currentApiConfig = isProduction ? apiConfig.production : apiConfig.testing;

  // 1) Called when user manually searches—now pushing history instead of replacing it
  const handleUserSearch = (searchQuery) => {
    setSearchSource('user');
    let newSearchType = searchType;
    if (searchQuery) {
      if (isAddress(searchQuery) && /\d/.test(searchQuery)) {
        newSearchType = "address";
      } else if (!isAddress(searchQuery)) {
        newSearchType = "location";
      }
    }
    const updatedFilters = { ...filters, [FILTER_TYPES.PAGE]: '1' };
    const newUrl = updateUrlWithSearchParams(
      updatedFilters,
      searchQuery,
      newSearchType,
      newSearchType === 'polygon' ? polygonCoordinates : null,
      null,
      {}
    );
    const currentUrl = window.location.pathname + window.location.search;
    if (newUrl !== currentUrl) {
      navigate(newUrl); // Push new history entry
      setUrlTriggeredUpdate(true);
    }
  };

  // 2) fetchListings (navigation calls updated to push history)
  const fetchListings = useCallback(
    async ({
      searchQuery,
      polygonCoords = null,
      newSearchType,
      triggeredBy = "",
      updatedFilters = null,
    }) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;
      const signal = controller.signal;

      if (
        (newSearchType === "polygon" && (!polygonCoords || polygonCoords.length === 0)) ||
        (newSearchType === "location" && (!searchQuery || searchQuery.trim() === ""))
      ) {
        Swal.fire({
          icon: "error",
          title: "Invalid Search",
          text: "Please provide valid search parameters and try again.",
        });
        return;
      }
      if (signal.aborted) return;

      setIsFetching(true);
      setIsFetchingNew(true);
      console.log(">>> fetchListings START.", { searchQuery, newSearchType });
      startProgress();

      let effectiveFilters = { ...defaultFilters, ...(updatedFilters || {}) };
      if (!updatedFilters) {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.forEach((value, key) => {
          effectiveFilters[key] = value;
        });
      }
      effectiveFilters.status_type = effectiveFilters.status_type || "ForSale";
      effectiveFilters.page = effectiveFilters.page || "1";

      let formattedPolygonCoords = null;
      if (typeof polygonCoords === "string" && polygonCoords.length > 0) {
        formattedPolygonCoords = polygonCoords;
      } else if (Array.isArray(polygonCoords) && polygonCoords.length > 0) {
        formattedPolygonCoords = polygonCoords
          .map((coord) => `${coord[0]} ${coord[1]}`)
          .join(",");
      }
      if (newSearchType === "polygon" && !formattedPolygonCoords) {
        Swal.fire({
          icon: "error",
          title: "Invalid Search",
          text: "Formatted polygon coordinates are invalid.",
        });
        return;
      }

      let cacheKey = null;
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        cacheKey = generateCacheKey(formattedPolygonCoords, effectiveFilters);
      }
      if (newSearchType === "polygon" && cacheKey) {
        const cachedPolygonData = getCachedPolygonSearch(
          formattedPolygonCoords,
          effectiveFilters
        );
        if (
          cachedPolygonData &&
          JSON.stringify(cachedPolygonData.filters) === JSON.stringify(effectiveFilters)
        ) {
          setListings(cachedPolygonData.results);
          setIsFetching(false);
          setIsFetchingNew(false);
          return;
        }
      }

      setSearchType(newSearchType);
      setResetData(true);
      prevSearchTypeRef.current = newSearchType;

      if (newSearchType === "polygon" && formattedPolygonCoords) {
        effectiveFilters.polygon = formattedPolygonCoords;
        delete effectiveFilters.address;
        delete effectiveFilters.location;
        updatePolygonCoordinates(formattedPolygonCoords);
      } else if (newSearchType === "location" && searchQuery) {
        effectiveFilters.location = searchQuery;
        delete effectiveFilters.address;
        delete effectiveFilters.polygon;
      }

      Object.keys(effectiveFilters).forEach((key) => {
        if (
          effectiveFilters[key] === "" ||
          effectiveFilters[key] === undefined ||
          effectiveFilters[key] === null ||
          (effectiveFilters.status_type === "ForRent" &&
            [
              "saleByAgent",
              "saleByOwner",
              "isForSaleForeclosure",
              "isNewConstruction",
              "isComingSoon",
              "isAuction",
              "isPendingUnderContract",
              "otherListings",
            ].includes(key))
        ) {
          delete effectiveFilters[key];
        }
      });

      const params = { ...effectiveFilters };
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        params.polygon = formattedPolygonCoords;
        delete params.address;
        delete params.location;
      } else if (newSearchType === "location") {
        delete params.polygon;
      }
      Object.keys(params).forEach((key) => {
        if (params[key] === "") {
          delete params[key];
        }
      });

      try {
        let details = null;
        if (newSearchType === "address" && isAddress(searchQuery)) {
          details = await fetchAdditionalDetailsByAddress(normalizeAddress(searchQuery));
        } else if (newSearchType === "zpid") {
          details = await fetchAdditionalDetails(searchQuery);
        } else {
          const { apiUrl, headers } = currentApiConfig;
          const response = await axios.get(apiUrl, { params, signal, headers });
          if (response.status !== 200) {
            throw new Error(`Server error: ${response.statusText}`);
          }
          const responseData = response.data;
          setTotalResultCount(responseData.totalResultCount ?? 0);
          setTotalPages(responseData.totalPages ?? 1);
          
          if (Array.isArray(responseData.props) && responseData.props.length > 0) {
            const validProps = responseData.props.filter(
              (property) => property.latitude !== null && property.longitude !== null
            );
            if (newSearchType === "polygon" && cacheKey) {
              setCachedPolygonSearch(formattedPolygonCoords, effectiveFilters, validProps);
            }
            setListings(validProps);
            validProps.forEach((_, i) => {
              const percentage = ((i + 1) / validProps.length) * 100;
              updateProgress(percentage);
            });
            setIsFetching(false);
            setIsFetchingNew(false);
            return validProps;
          } else if (responseData.zpid) {
            details = responseData;
          }
        }

        if (details) {
          setListings([details]);
          setSelectedListing(details);
          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            details.zpid,
            defaultFilters
          );
          const currentUrl = `${window.location.pathname}?${window.location.search}`;
          if (newUrl !== currentUrl) {
            navigate(newUrl); // Push history entry
          }
          setIsFetching(false);
          setIsFetchingNew(false);
          return [details];
        } else {
          setListings([]);
          Swal.fire({
            icon: "error",
            title: "No Results Found",
            text: "No properties found! Adjust your filters and try again.",
          });
          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            undefined,
            defaultFilters
          );
          const currentUrl = `${window.location.pathname}?${window.location.search}`;
          if (newUrl !== currentUrl) {
            navigate(newUrl);
          }
          setIsFetching(false);
          setIsFetchingNew(false);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error);
        }
        setListings([]);
        const newUrl = updateUrlWithSearchParams(
          params,
          searchQuery,
          newSearchType,
          formattedPolygonCoords || undefined,
          undefined,
          defaultFilters
        );
        const currentUrl = `${window.location.pathname}?${window.location.search}`;
        if (newUrl !== currentUrl) {
          navigate(newUrl);
        }
        setIsFetching(false);
        setIsFetchingNew(false);
      } finally {
        const urlParams = new URLSearchParams(location.search);
        const maybeZpid = urlParams.get('zpid');
        const maybeAddress = urlParams.get('address');
        if (!maybeZpid && !maybeAddress) {
          setIsFetching(false);
          setIsFetchingNew(false);
        }
        fetchTriggeredRef.current = false;
        completeProgress();
      }
      setSearchCounter((prev) => prev + 1);
      setUserHasInteracted(false);
    },
    [
      defaultFilters,
      location.search,
      navigate,
      startProgress,
      completeProgress,
      updateProgress,
      setSearchType,
      setListings,
      setSelectedListing,
      setTotalResultCount,
      setTotalPages,
      setUserHasInteracted,
      currentApiConfig,
    ]
  );

  const fetchDetails = useCallback(
    async (zpid, address) => {
      setIsFetching(true);
      setIsFetchingNew(true);
      let details = null;
      if (zpid) {
        try {
          details = await fetchAdditionalDetails(zpid);
        } catch (error) {
          console.error(`Failed to fetch details by zpid: ${zpid}`, error);
        }
      }
      if (!details && address) {
        try {
          details = await fetchAdditionalDetailsByAddress(address);
        } catch (error) {
          console.error(`Failed to fetch details by address: ${address}`, error);
          throw error;
        }
      }
      if (!details) {
        console.warn("No details fetched from either zpid or address.");
        Swal.fire({
          title: 'Error!',
          html: 'Sorry, we had some trouble fetching the property details.',
          icon: 'error',
        });
        setIsFetching(false);
        setIsFetchingNew(false);
      }
      return details;
    },
    []
  );

  useEffect(() => {
    if (urlTriggeredUpdate && !fetchTriggeredRef.current && location.search !== lastFetchedSearchRef.current) {
      fetchTriggeredRef.current = true;
      lastFetchedSearchRef.current = location.search;
      const queryParams = new URLSearchParams(location.search);
      const zpid = queryParams.get('zpid');
      const address = queryParams.get('address');
      const locationParam = queryParams.get('location');
      const polygonCoords = queryParams.get('polygon');
      if (zpid || address) {
        startProgress();
        fetchDetails(zpid, address)
          .then((details) => {
            if (details) {
              setListings([details]);
              setSelectedListing(details);
              setSelectedZpid(details.zpid);
              setShouldOpenModalOnLoad(true);
            }
          })
          .catch((error) => {
            if (error.name !== 'AbortError') {
              console.error("Error fetching details:", error);
              Swal.fire('Error!', 'Failed to fetch property details.', 'error');
            }
          })
          .finally(() => {
            setUrlTriggeredUpdate(false);
            fetchTriggeredRef.current = false;
            setIsDataReady(true);
            completeProgress();
          });
      } else if (locationParam || polygonCoords) {
        startProgress();
        const searchQuery = useQuery || locationParam || '';

        let newSearchType;

        if (polygonCoords) {
          newSearchType = 'polygon';
        } else if (zpid) {
          newSearchType = 'zpid';  // ✅ ZPID TAKES HIGHEST PRIORITY
        } else if (address) {
          newSearchType = 'address'; // ✅ ADDRESS TAKES PRIORITY OVER LOCATION
        } else if (locationParam && isAddress(locationParam) && /\d/.test(locationParam)) {
          newSearchType = 'address'; // ✅ FALLBACK: DETECTED ADDRESS
        } else {
          newSearchType = 'location'; // ✅ DEFAULT TO LOCATION
        }

        // ✅ ENSURE STATE UPDATES BEFORE FETCHING LISTINGS
        setSearchType(newSearchType);

        fetchListings({
          searchQuery,
          polygonCoords,
          newSearchType,
          triggeredBy: 'urlLoad',
          updatedFilters: filtersRef.current,
        })
          .finally(() => {
            setUrlTriggeredUpdate(false);
            fetchTriggeredRef.current = false;
            setIsDataReady(true);
            completeProgress();
          });
      } else {
        setUrlTriggeredUpdate(false);
        fetchTriggeredRef.current = false;
        setIsDataReady(true);
        completeProgress();
      }
    }
  }, [urlTriggeredUpdate, location.search, useQuery, fetchListings, fetchDetails, setUrlTriggeredUpdate, completeProgress, setIsDataReady]);


  const [isOffline] = useState(!navigator.onLine);
  const [isAnalyticsInit, setIsAnalyticsInit] = useState(false);

  useEffect(() => {
    async function setLocationForAnalyticsAndHotjar() {
      try {
        if (navigator.geolocation) {
          const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
          const fetchAndSendLocation = async () => {
            try {
              const location = await getUserLocation();
              if (location) {
                hj('tagRecording', [
                  `city:${location.city}`,
                  `state:${location.state}`,
                  `country:${location.country}`,
                  `postalCode:${location.postalCode}`,
                  `timeZone:${location.timeZone}`,
                  `latitude:${location.latitude}`,
                  `longitude:${location.longitude}`,
                  `metroCode:${location.metroCode}`
                ]);
                if (typeof window.gtag === 'function') {
                  window.gtag('event', 'location_detected', {
                    user_city: location.city,
                    user_state: location.state,
                    user_country: location.country,
                    user_postal_code: location.postalCode,
                    user_time_zone: location.timeZone,
                    user_latitude: location.latitude,
                    user_longitude: location.longitude,
                    user_metro_code: location.metroCode,
                  });
                }
              }
            } catch (error) {
              console.error('Location detection failed:', error);
            }
          };
          if (permissionStatus.state === 'granted') {
            fetchAndSendLocation();
          }
          permissionStatus.onchange = () => {
            if (permissionStatus.state === 'granted') {
              fetchAndSendLocation();
            }
          };
        }
      } catch (error) {
        console.error('Location detection failed:', error);
      }
    }
    setLocationForAnalyticsAndHotjar();
  }, []);

  useEffect(() => {
    if (!isAnalyticsInit && !isOffline) {
      isSupported().then((supported) => {
        if (supported) {
          try {
            const analytics = getAnalytics(app);
            console.log("Firebase Analytics initialized:", analytics);
          } catch (err) {
            console.error("Firebase analytics init failed:", err);
          }
        }
      });
      setIsAnalyticsInit(true);
    }
  }, [isOffline, isAnalyticsInit]);

  useEffect(() => {
    if (location.search !== currentLocationSearch) {
      setCurrentLocationSearch(location.search);
    }
  }, [location.search, currentLocationSearch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const updatedFilters = {};
    let newSearchQuery = useQuery;
    let newSearchType = searchType;
    urlParams.forEach((value, key) => {
      if (ALL_FILTER_PARAMS.includes(key)) {
        updatedFilters[key] = value;
      }
      if (key === 'location') {
        newSearchQuery = value;
        newSearchType = 'location';
      }
      if (key === 'polygon') {
        newSearchType = 'polygon';
      }
    });
    if (Object.keys(updatedFilters).length > 0) {
      setFilters((prev) => {
        if (JSON.stringify(prev) !== JSON.stringify(updatedFilters)) {
          return { ...prev, ...updatedFilters };
        }
        return prev;
      });
    }
    if (newSearchQuery !== useQuery) {
      setUseQuery(newSearchQuery);
    }
    if (newSearchType !== searchType) {
      setSearchType(newSearchType);
    }
  }, [location.search]);

  const handleClearFilters = useCallback(() => {
    setFilters(defaultFilters);
    setResetData(true);
  }, [defaultFilters]);

  useEffect(() => {
    if (resetData) {
      setListings([]);
      setFilteredData([]);
      setResetData(false);
    }
  }, [resetData, setFilteredData]);

  function NormalizeRoute() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (location.pathname !== location.pathname.toLowerCase()) {
        navigate(location.pathname.toLowerCase(), { replace: true });
      }
    }, [location.pathname, navigate]);

    return null;
  }

  return (
    <>
      <ErrorBoundary>
        <ScrollLockProvider>
          <ViewModeProvider>
            <TutorialProvider>
              <ErrorProvider>
                <header>
                  <Navbar
                    closeAuthModal={closeAuthModal}
                    showAuthModal={showAuthModal}
                    setShowAuthModal={setShowAuthModal}
                  />
                  {showAuthModal && (
                    <AuthModal activeTab={activeTab} onClose={closeAuthModal} />
                  )}
                </header>

                <SpeedInsights />
                <main id="main-content" className="flex flex-col flex-1" style={{ minHeight: '100vh' }}>
                  <div className="mainContent-container bg-white dark:bg-gradient-to-br from-black via-gray-900 to-indigo-950">
                    <NormalizeRoute />
                    <Routes>
                      <Route path="*" element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <NotFound />
                        </Suspense>
                      } />
                      <Route
                        path="/"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Willamette Valley Real Estate Agents, Realtors | Spear Real Estate Group"
                                description="Leaders in the real estate marketplace. Search millions of for-sale and rental properties, compare home values, and connect with experienced local professionals at Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="real estate, property, home, sale, buy, realtor, Willamette Valley"
                              />
                              <Home
                                handleUserSearch={handleUserSearch}
                                fetchListings={fetchListings}
                                listings={listings}
                                setSearchType={setSearchType}
                                filters={filters}
                                useQuery={useQuery}
                                setFilters={setFilters}
                                setAppliedFilters={setAppliedFilters}
                                handleClearFilters={handleClearFilters}
                                searchType={searchType}
                                setListings={setListings}
                                setFilteredData={setFilteredData}
                                fetchAdditionalDetails={fetchAdditionalDetails}
                                defaultFilters={defaultFilters}
                                updateUrlWithSearchParams={updateUrlWithSearchParams}
                                setUseQuery={setUseQuery}
                                appliedFilters={appliedFilters}
                                urlTriggeredUpdate={urlTriggeredUpdate}
                                setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                                startProgress={startProgress}
                                completeProgress={completeProgress}
                                updateProgress={updateProgress}
                                loading={initialLoadingDone}
                              />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/home"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Willamette Valley Real Estate Agents, Realtors | Spear Real Estate Group"
                                description="Leaders in the real estate marketplace. Search millions of for-sale and rental properties, compare home values, and connect with experienced local professionals at Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="real estate, property, home, sale, buy, realtor, Willamette Valley"
                              />
                              <Home
                                handleUserSearch={handleUserSearch}
                                fetchListings={fetchListings}
                                listings={listings}
                                setSearchType={setSearchType}
                                filters={filters}
                                useQuery={useQuery}
                                setFilters={setFilters}
                                setAppliedFilters={setAppliedFilters}
                                handleClearFilters={handleClearFilters}
                                searchType={searchType}
                                setListings={setListings}
                                setFilteredData={setFilteredData}
                                fetchAdditionalDetails={fetchAdditionalDetails}
                                defaultFilters={defaultFilters}
                                updateUrlWithSearchParams={updateUrlWithSearchParams}
                                setUseQuery={setUseQuery}
                                appliedFilters={appliedFilters}
                                urlTriggeredUpdate={urlTriggeredUpdate}
                                setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                                startProgress={startProgress}
                                completeProgress={completeProgress}
                                updateProgress={updateProgress}
                                loading={initialLoadingDone}
                              />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/listings/:locationOrZpid?/:zpid?/"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <Listings
                              currentPage={parseInt(filters.page || "1", 10)}
                              listingsCount={listings.length}
                              totalResultCount={totalResultCount}
                              setTotalResultCount={setTotalResultCount}
                              totalPages={totalPages}
                              setTotalPages={setTotalPages}
                              onPageChange={onPageChange}
                              onSearch={handleUserSearch}
                              isFetching={isFetching}
                              setIsFetching={setIsFetching}
                              isFetchingNew={isFetchingNew}
                              setIsFetchingNew={setIsFetchingNew}
                              startProgress={startProgress}
                              completeProgress={completeProgress}
                              updateProgress={updateProgress}
                              loading={loading}
                              progress={progress}
                              shouldOpenModalOnLoad={shouldOpenModalOnLoad}
                              setShouldOpenModalOnLoad={setShouldOpenModalOnLoad}
                              setUserHasInteracted={setUserHasInteracted}
                              userHasInteracted={userHasInteracted}
                              queryParams={{ zpid, address, addressTriggered }}
                              selectedListing={selectedListing}
                              setSelectedListing={setSelectedListing}
                              selectedZpid={selectedZpid}
                              setSelectedZpid={setSelectedZpid}
                              cachedPropertyDetails={cachedPropertyDetails}
                              setCachedPropertyDetails={setCachedPropertyDetails}
                              isDataReady={isDataReady}
                              setIsDataReady={setIsDataReady}
                              urlTriggeredUpdate={urlTriggeredUpdate}
                              setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                              updateUrlWithSearchParams={updateUrlWithSearchParams}
                              fetchListings={fetchListings}
                              listings={listings}
                              filters={filters}
                              setFilters={setFilters}
                              useQuery={useQuery}
                              setUseQuery={setUseQuery}
                              appliedFilters={appliedFilters}
                              setAppliedFilters={setAppliedFilters}
                              handleClearFilters={handleClearFilters}
                              searchType={searchType}
                              setSearchType={setSearchType}
                              setListings={setListings}
                              setFilteredData={setFilteredData}
                              fetchAdditionalDetails={fetchAdditionalDetails}
                              defaultFilters={defaultFilters}
                            />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/auth/google/callback"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <GoogleAuthCallback />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/reset"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <Reset />
                          </Suspense>
                        }
                      />

                      <Route path="login" element={<AuthModal activeTab="login" onClose={closeAuthModal} />} />
                      <Route path="register" element={<AuthModal activeTab="register" onClose={closeAuthModal} />} />

                      <Route
                        path="/featured-listings"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <FeaturedListings handleUserSearch={handleUserSearch} />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/dashboard"
                        element={
                          <ProtectedRoute>
                            <Suspense fallback={<LoadingSpinner />}>
                              <ProfileSettings to="/dashboard/profilesettings" />
                            </Suspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/dashboard/:tabName"
                        element={
                          <ProtectedRoute>
                            <Suspense fallback={<LoadingSpinner />}>
                              <Dashboard />
                            </Suspense>
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/contact"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Contact Us - Spear Real Estate Group"
                                description="Get in touch with Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/contact"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="contact, real estate, Spear Real Estate Group"
                              />
                              <Contact />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/about"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="About Us - Spear Real Estate Group"
                                description="Learn more about Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/about"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="about us, real estate, Spear Real Estate Group"
                              />
                              <AboutUs />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/policy"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Privacy Policy - Spear Real Estate Group"
                                description="Read our privacy policy."
                                canonical="https://www.spearrealestategroup.com/policy"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="privacy policy, real estate, Spear Real Estate Group"
                              />
                              <PrivacyPolicy />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/terms"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Terms of Service - Spear Real Estate Property Finder"
                                description="Read our terms of service."
                                canonical="https://www.spearrealestategroup.com/terms"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="terms of service, real estate, Spear Real Estate Group"
                              />
                              <TermsOfService />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/rate-search"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Search Mortgage Rates - Spear Real Estate Group"
                                description="View current mortgage rates and compare lenders."
                                canonical="https://www.spearrealestategroup.com/rate-search"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="mortgage rates, lenders, real estate, Spear Real Estate Group"
                              />
                              <SearchMortgageRates />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/mortgage-calculator"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Mortgage Calculator - Spear Real Estate Group"
                                description="Calculate your mortgage payments with our mortgage calculator."
                                canonical="https://www.spearrealestategroup.com/mortgage-calculator"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="mortgage calculator, real estate, Spear Real Estate Group"
                              />
                              <MortgageCalculator />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/search-lenders"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Search Lenders - Spear Real Estate Group"
                                description="Find the best lenders with Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/search-lenders"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="search lenders, real estate, Spear Real Estate Group"
                              />
                              <SearchLenders />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/agent-landing"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Find a Top Buyer Agent"
                                description="Find the best real estate agents with our agent finder landing page."
                                canonical="https://www.spearrealestategroup.com/agent-landing"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="find agent, real estate agents, Spear Real Estate Group"
                              />
                              <AgentFinderLanding />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/agent-finder"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Find a Top Agent - Spear Real Estate Group"
                                description="Discover the best real estate agents in your area with Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/agent-finder"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="agent finder, real estate agent, top agents, Spear Real Estate Group"
                              />
                              <AgentFinderForm />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/refinance"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Refinance Options - Spear Real Estate Group"
                                description="Estimate and calculate your refinance options with our combined tool."
                                canonical="https://www.spearrealestategroup.com/refinance"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="refinance estimate, refinance calculator, real estate, Spear Real Estate Group"
                              />
                              <RefinancePage />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/list-with-us"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="List with Us - Spear Real Estate Group"
                                description="List your home with Spear Real Estate Group."
                                canonical="https://www.spearrealestategroup.com/list-with-us"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="list with us, list home, real estate, Spear Real Estate Group"
                              />
                              <ListWithUs />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/home-value"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Home Value - Spear Real Estate Group"
                                description="Find out the value of your home."
                                canonical="https://www.spearrealestategroup.com/home-value"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="home value, real estate, Spear Real Estate Group"
                              />
                              <HomeValue />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/rental-market-trends"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Rental Market Trends - Spear Real Estate Group"
                                description="Stay updated with the latest rental market trends."
                                canonical="https://www.spearrealestategroup.com/rental-market-trends"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="rental market trends, rentals, real estate, Spear Real Estate Group"
                              />
                              <RentalMarketTrends />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/investor-resources/investment-calculator"
                        element={<Navigate replace to="/investment-calculators" />}
                      />

                      <Route
                        path="/renter-resources/rental-market-trends"
                        element={<Navigate replace to="/rental-market-trends" />}
                      />

                      <Route
                        path="/investment-calculators"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Investment Calculators - Spear Real Estate Group"
                                description="Calculate your investment options with our calculators."
                                canonical="https://www.spearrealestategroup.com/investment-calculators"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="investment calculators, investments, real estate, Spear Real Estate Group"
                              />
                              <InvestmentCalculator />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/blog"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <BlogList />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/blog/:postId"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <BlogPostDetail />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/sitemap"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Sitemap - Spear Real Estate Group"
                                description="Explore the sitemap of Spear Real Estate Group to find all available pages and resources."
                                canonical="https://www.spearrealestategroup.com/sitemap"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="sitemap, real estate, Spear Real Estate Group"
                              />
                              <SitemapPage />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/schedule"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Book a Meeting - Spear Real Estate Group"
                                description="Schedule a meeting with us using our online booking system."
                                canonical="https://www.spearrealestategroup.com/schedule"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="schedule meeting, booking, real estate, Spear Real Estate Group"
                              />
                              <BookingPage />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/resources"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Resources - Spear Real Estate Group"
                                description="Explore resources for buyers, sellers, investors, and renters. Find expert guides, moving assistance, and real estate insights."
                                canonical="https://www.spearrealestategroup.com/resources"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="real estate resources, buyer guide, seller guide, investor guide, renter resources, Spear Real Estate Group"
                              />
                              <ResourcesLanding />
                            </>
                          </Suspense>
                        }
                      />

                      <Route
                        path="/seller-resources"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Seller Resources - Spear Real Estate Group"
                                description="Find resources for home sellers."
                                canonical="https://www.spearrealestategroup.com/seller-resources"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="seller resources, home sellers, real estate, Spear Real Estate Group"
                              />
                              <SellerResources />
                            </>
                          </Suspense>
                        }
                      />
                      <Route path="/resources/seller-resources" element={<Navigate replace to="/seller-resources" />} />

                      {/* Updated Buyer Resources Route with proper prop passing */}
                      <Route
                        path="/buyer-resources"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Buyer Resources - Spear Real Estate Group"
                                description="Find resources for home buyers."
                                canonical="https://www.spearrealestategroup.com/buyer-resources"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="buyer resources, home buyers, real estate, Spear Real Estate Group"
                              />
                              <BuyerResources
                                handleUserSearch={handleUserSearch}
                                fetchListings={fetchListings}
                                filters={filters}
                                handleErasePolygon={() => { }}
                              />
                            </>
                          </Suspense>
                        }
                      />
                      <Route path="/resources/buyer-resources" element={<Navigate replace to="/buyer-resources" />} />

                      <Route
                        path="/renter-resources"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Renter Resources - Spear Real Estate Group"
                                description="Find resources for renters."
                                canonical="https://www.spearrealestategroup.com/renter-resources"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="renter resources, renters, real estate, Spear Real Estate Group"
                              />
                              <RenterResources />
                            </>
                          </Suspense>
                        }
                      />
                      <Route path="/resources/renter-resources" element={<Navigate replace to="/renter-resources" />} />

                      <Route
                        path="/investor-resources"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Investor Resources - Spear Real Estate Group"
                                description="Find resources for investors."
                                canonical="https://www.spearrealestategroup.com/investor-resources"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="investor resources, real estate investment, Spear Real Estate Group"
                              />
                              <InvestorResources />
                            </>
                          </Suspense>
                        }
                      />
                      <Route path="/resources/investor-resources" element={<Navigate replace to="/investor-resources" />} />

                      <Route
                        path="/get-moving"
                        element={
                          <Suspense fallback={<LoadingSpinner />}>
                            <>
                              <SEO
                                title="Get Moving with Ease - Spear Real Estate Group"
                                description="Let us handle your entire move from start to finish. Trusted movers, contractors, and home repair professionals for a stress-free experience."
                                canonical="https://www.spearrealestategroup.com/get-moving"
                                ogImage="https://www.spearrealestategroup.com/assets/favicon.svg"
                                keywords="moving services, movers, contractors, home repairs, stress-free move, Spear Real Estate Group"
                              />
                              <GetMoving />
                            </>
                          </Suspense>
                        }
                      />
                      <Route path="/resources/get-moving" element={<Navigate replace to="/get-moving" />} />
                    </Routes>
                    <footer>
                      <Suspense fallback={<LoadingSpinner />}>
                        <SiteFooter
                          handleUserSearch={handleUserSearch}
                          setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                          loading={loading}
                        />
                      </Suspense>
                    </footer>
                  </div>
                </main>
              </ErrorProvider>
            </TutorialProvider>
          </ViewModeProvider>
        </ScrollLockProvider>
      </ErrorBoundary>
      <Analytics />
    </>
  );
}

export default App;
