// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/ContactDropdown.jsx
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { CiBookmarkCheck, MdEmail, BsCalendar, MdOutlinePrivacyTip, MdPhone, MdInfo } from '../../assets/svgIcons/svgIcons.js';

const categories = [
    {
        name: 'Contact',
        href: '/contact',
        icon: CiBookmarkCheck,
    },
    {
        name: 'About',
        href: '/about',
        icon: MdInfo,
    },
    {
        name: 'Email',
        href: 'mailto:team@spearrealestate.homes',
        icon: MdEmail,
    },
    {
        name: 'Call',
        href: 'tel:+15037394935',
        icon: MdPhone,
    },
    {
        name: 'Schedule a Meeting',
        href: '/schedule',
        icon: BsCalendar,
    },
    {
        name: 'Privacy Policy',
        href: '/policy',
        icon: MdOutlinePrivacyTip,
    }
];

// Provide a default no‑op for closeMobileMenu to avoid errors
function ContactDropdown({ closeMobileMenu = () => {} }) {
    // Custom handler for the Email category
    const handleEmailClick = (e) => {
        e.preventDefault();
        // Attempt to open the native email app
        window.location.href = 'mailto:team@spearrealestate.homes';
        // After a 1-second delay, if the document is still visible, redirect to the contact page
        setTimeout(() => {
            if (!document.hidden) {
                window.location.href = '/contact';
            }
        }, 1000);
    };

    return (
        <Suspense
            fallback={
                <div
                    className="mx-auto w-12 h-12 border-4 border-t-4 border-gray-200 border-t-purple-500 rounded-full animate-spin transition-opacity duration-300 opacity-100"
                    role="status"
                    aria-label="Loading"
                ></div>
            }
        >
            <div id="contact-dropdown" role="menu" aria-label="Contact Menu">
                <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                >
                    {categories.map((category, index) => {
                        const IconComponent = category.icon;
                        if (category.name.toLowerCase() === 'email') {
                            return (
                                <li key={index} role="none">
                                    <a
                                        href={category.href}
                                        onClick={(e) => {
                                            closeMobileMenu();
                                            handleEmailClick(e);
                                        }}
                                        className="block flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        role="menuitem"
                                        aria-label={category.name}
                                    >
                                        <IconComponent
                                            className="w-5 h-5 text-purple-500"
                                            aria-hidden="true"
                                        />
                                        <span>{category.name}</span>
                                    </a>
                                </li>
                            );
                        }
                        return (
                            <li key={index} role="none">
                                <Link
                                    to={category.href}
                                    onClick={closeMobileMenu}
                                    className="block flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                    role="menuitem"
                                    aria-label={category.name}
                                >
                                    <IconComponent
                                        className="w-5 h-5 text-purple-500"
                                        aria-hidden="true"
                                    />
                                    <span>{category.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Suspense>
    );
}

export default ContactDropdown;
