// getUserLocation.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/getUserLocation.js

export async function getUserLocation() {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await fetch(`/api/fetchUserLocation?lat=${latitude}&lng=${longitude}`);
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const contentType = response.headers.get("content-type");
              if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Received non-JSON response from location API");
              }
              const data = await response.json();
              resolve({
                city: data.city,
                state: data.state,
                country: data.country,
                postalCode: data.postalCode || "",
                timeZone: data.timeZone || "",
                latitude,
                longitude,
                metroCode: data.metroCode || ""
              });
            } catch (error) {
              reject(error);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    } else {
      return null;
    }
  }
  