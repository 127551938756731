// fetchAdditionalDetails.jsx
import axios from "axios";
import Swal from "sweetalert2";
import {
  setCachedPropertyDetail,
  getCachedPropertyDetail,
  normalizeKey
} from "../utils/cache.js";
import { normalizeAddress } from "../utils/addressUtils.js";

export const fetchAdditionalDetails = async (zpid) => {
  // Ensure zpid is a valid string
  zpid = String(zpid).trim();
  console.log("fetchAdditionalDetails called with zpid:", zpid);

  if (!zpid) {
    console.error("Invalid or missing zpid:", zpid);
    throw new Error("ZPID must be a valid string and cannot be empty");
  }

  // 1) Check local React cache
  const normalizedZpid = normalizeKey(zpid);
  let cachedDetail = getCachedPropertyDetail(normalizedZpid);
  if (cachedDetail && cachedDetail.zpid) {
    console.log(`Local cache hit for zpid ${normalizedZpid}:`, cachedDetail);
    return cachedDetail;
  }

  console.log(`Local cache miss for zpid ${normalizedZpid}. Fetching from API...`);

  // 2) Decide which endpoint to use
  const isProduction = process.env.NODE_ENV === "production";

  let apiUrl = "";
  let requestConfig = {};

  if (isProduction) {
    // Production: call your serverless endpoint at /api/propertyDetails
    // which is actually handled by the Worker + Momento cache
    if (window.location.hostname.includes("vercel.app")) {
      apiUrl = `https://${window.location.hostname}/api/propertyDetails`;
    } else {
      apiUrl = "https://www.spearrealestategroup.com/api/propertyDetails";
    }

    requestConfig = {
      method: "GET",
      url: apiUrl,
      params: { zpid: normalizedZpid },
      headers: {
        "Content-Type": "application/json"
      }
    };
  } else {
    // Development: call Zillow’s RapidAPI directly
    const host = "zillow-com1.p.rapidapi.com";
    const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;
    apiUrl = `https://${host}/property`;
    requestConfig = {
      method: "GET",
      url: apiUrl,
      params: { zpid: normalizedZpid },
      headers: {
        "x-rapidapi-key": key,
        "x-rapidapi-host": host
      }
    };
  }

  try {
    console.log(`Fetching details from ${apiUrl} with zpid=${normalizedZpid}`);
    const response = await axios.request(requestConfig);
    const data = response.data;

    if (!data || !data.zpid) {
      throw new Error("Invalid data received from API");
    }

    // (Optional) Log if the Worker returned a cache hit
    const xCacheHeader = response.headers["x-cache"];
    if (xCacheHeader === "HIT") {
      console.log("Momento Worker Cache HIT for zpid:", normalizedZpid);
    } else {
      console.log("Momento Worker Cache MISS for zpid:", normalizedZpid);
    }

    // 4) Cache the result in local (React) cache
    if (data.address) {
      const normalizedAddr = normalizeAddress(
        `${data.address.streetAddress} ${data.address.city} ${data.address.state} ${data.address.zipcode} USA`
      );
      setCachedPropertyDetail(normalizeKey(normalizedAddr), data);
    }
    setCachedPropertyDetail(normalizedZpid, data);

    return data;
  } catch (error) {
    console.error(`Error fetching details for zpid ${normalizedZpid}:`, error);
    return null;
  }
};
