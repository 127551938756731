// NavBar.jsx
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/NavBar.jsx
import React, {
  Suspense,
  useRef,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  lazy,
} from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext.js";
import { logout } from "../firebase/Firebase.js";
import { Dropdown } from "flowbite-react";
import FinancingDropdown from "./FinancingDropdown.jsx";
import SellDropdown from "./SellDropdown.jsx";
import RentDropdown from "./RentDropdown.jsx";
import ContactDropdown from "./ContactDropdown.jsx";
import BuyDropdown from "./BuyDropdown.jsx";
import InvestDropdown from "./InvestDropdown.jsx";
import Swal from "sweetalert2";
import ProfileMenu from "./ProfileMenu.jsx";
import { useScrollLock } from "../../contexts/scrollLockContext.js";
import {
  PiHouseLineLight,
  CgMenuLeftAlt,
  RiArrowDropDownLine,
  GoShare,
  TbHomeSearch,
  MdOutlineCancel,
  FaMoon,
  WiDayCloudy,
} from "../../assets/svgIcons/svgIcons.js";

const FacebookShareButton = lazy(() =>
  import("react-share").then((module) => ({
    default: module.FacebookShareButton,
  }))
);
const TwitterShareButton = lazy(() =>
  import("react-share").then((module) => ({
    default: module.TwitterShareButton,
  }))
);
const WhatsappShareButton = lazy(() =>
  import("react-share").then((module) => ({
    default: module.WhatsappShareButton,
  }))
);
const EmailShareButton = lazy(() =>
  import("react-share").then((module) => ({
    default: module.EmailShareButton,
  }))
);
const FacebookIcon = lazy(() =>
  import("react-share").then((module) => ({
    default: module.FacebookIcon,
  }))
);
const TwitterIcon = lazy(() =>
  import("react-share").then((module) => ({
    default: module.TwitterIcon,
  }))
);
const WhatsappIcon = lazy(() =>
  import("react-share").then((module) => ({
    default: module.WhatsappIcon,
  }))
);
const EmailIcon = lazy(() =>
  import("react-share").then((module) => ({
    default: module.EmailIcon,
  }))
);
const TbCopy = React.lazy(() =>
  import("../../assets/svgIcons/svgIcons.js").then((module) => ({
    default: module.TbCopy,
  }))
);

// Memoized Logo Component for Performance Optimization
const MemoizedLogo = React.memo(() => (
  <img
    src={`${process.env.PUBLIC_URL}/assets/favicon.svg`}
    alt="Spear Real Estate Group Logo"
    width="70"         // intrinsic width – space is reserved
    height="70"        // intrinsic height – space is reserved
    className="w-[50px] sm:w-[60px] md:w-[70px] h-[70px] object-contain"
    fetchpriority="high"
  />
));

function Navbar() {
  const { isAuthenticated } = useContext(AuthContext);
  const { stopScroll, restoreScroll } = useScrollLock();
  const navigate = useNavigate();
  const location = useLocation();

  // State to manage mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // State to manage the active dropdown
  const [activeDropdown, setActiveDropdown] = useState(null);

  const isUserAuthenticated = useMemo(() => isAuthenticated, [isAuthenticated]);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [copied, setCopied] = useState(false);

  // Ref for managing clicks outside dropdowns
  const dropdownContainerRef = useRef(null);

  // Handler to copy link to clipboard
  const handleCopyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      Swal.fire({
        title: "Success!",
        text: "Link copied to clipboard.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy link: ", err);
      Swal.fire("Error!", "Failed to copy link.", "error");
    }
  }, []);

  // Handler to logout
  const handleLogout = useCallback(async () => {
    try {
      await logout();
      Swal.fire({
        title: "Logged Out!",
        text: "You have successfully logged out.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      navigate("/home");
    } catch (error) {
      Swal.fire("Error!", "Failed to log out.", "error");
    }
  }, [navigate]);

  // Toggle functions for each dropdown
  const toggleDropdown = (dropdownName) => {
    setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const toggleContactDropdown = () => toggleDropdown("contact");
  const toggleBuyDropdown = () => toggleDropdown("buy");
  const toggleSellDropdown = () => toggleDropdown("sell");
  const toggleInvestDropdown = () => toggleDropdown("invest");
  const toggleRentDropdown = () => toggleDropdown("rent");
  const toggleFinanceDropdown = () => toggleDropdown("finance");

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    !isMobileMenuOpen ? stopScroll() : restoreScroll();
  };

  // Close mobile menu
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    restoreScroll();
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(event.target)
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close mobile menu on route change
  useEffect(() => {
    if (isMobileMenuOpen) {
      closeMobileMenu();
    }
    // Reset all dropdowns
    setActiveDropdown(null);
  }, [location]);

  // Dark Mode State
  const [isDarkMode, setIsDarkMode] = useState(() => {
    if (localStorage.getItem("theme") === "dark") {
      return true;
    } else if (localStorage.getItem("theme") === "light") {
      return false;
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  // Apply Dark Mode
  useEffect(() => {
    const rootElement = document.documentElement;
    if (isDarkMode) {
      rootElement.classList.add("dark");
    } else {
      rootElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  // Toggle Dark Mode
  const handleDarkModeToggle = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  return (
    <nav
      ref={dropdownContainerRef}
      className="navMenu relative flex items-center h-[80px] min-w-[100vw] dark:bg-gradient-to-br from-black via-gray-900 to-indigo-950 dark:border-b-0 mx-auto max-w-screen-xl px-2 sm:px-4 lg:px-8 dark:border-b dark:border-purple-200 bg-white border-b border-gray-200 dark:py-2 justify-between"
      id="navBar"
      role="navigation"
      aria-label="Main Navigation"
      style={{
        zIndex: 20, // (optional if you want the map on top)
      }}
    >
      {/* Left Section */}
      <div className="flex items-center space-x-2 justify-start flex-none">
        {/* Dark Mode Toggle */}
        <button
          id="dark-mode-toggle"
          onClick={handleDarkModeToggle}
          className="text-gray-800 dark:text-white rounded-xl ring-0 focus:ring-0 hover:text-purple-500 dark:hover:text-purple-400 transition duration-300"
          aria-label="Toggle dark mode"
        >
          {isDarkMode ? (
            <FaMoon className="mt-1 w-6 h-6 text-amber-200 hover:text-white" />
          ) : (
            <WiDayCloudy className="mt-2 w-6 h-6 text-purple-400 hover:text-yellow-300" />
          )}
        </button>

        {/* Profile Menu */}
        {isUserAuthenticated ? (
          <ProfileMenu
            setShowProfileMenu={setShowProfileMenu}
            handleLogout={handleLogout}
            isUserAuthenticated={isUserAuthenticated}
          />
        ) : (
          <div className="userlogin">
            <ProfileMenu
              setShowProfileMenu={setShowProfileMenu}
              isUserAuthenticated={isUserAuthenticated}
            />
          </div>
        )}

        {/* Home Link */}
        <Link
          className="w-8 h-8 flex items-center justify-center rounded-full bg-transparent text-purple-400 transition duration-300 ease-in-out hover:-translate-y-1"
          to="/home"
          aria-label="Go to Home"
        >
          <PiHouseLineLight className="!stroke-[3px] cursor-pointer w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-purple-600 dark:text-purple-200 hover:text-purple-300 dark:hover:text-purple-400 transition ease-in-out duration-150" />
        </Link>

        {/* Share Dropdown */}
        <div
          id="navShareIcon"
          className="relative mx-2 hover:text-slate-600 dark:hover:text-slate-50"
          title="Share"
          aria-haspopup="menu"
          aria-expanded={activeDropdown === "share"} // Dynamically update based on dropdown state
          role="button"
          tabIndex={0} // Ensure the element is keyboard-focusable
          onClick={() => toggleDropdown("share")}
        >

          <Dropdown
            label={
              <GoShare
                className="cursor-pointer w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-purple-600 dark:text-purple-200 hover:text-purple-300 dark:hover:text-purple-400 transition ease-in-out duration-150"
                aria-label="Share links dropdown"
              />
            }
            inline={true}
            arrowIcon={false}
          >
            <Suspense
              fallback={
                <div
                  className="mx-auto w-12 h-12 border-4 border-t-4 border-gray-200 border-t-purple-500 rounded-full animate-spin transition-opacity duration-300 opacity-100"
                  role="status"
                  aria-label="Loading"
                />
              }
            >
              <div
                className="flex flex-col gap-2 p-4"
                role="menu"
                aria-label="Share options"
              >
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon
                    className="cursor-pointer my-2 w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 hover:translate-y-1"
                    id="facebookIcon"
                    aria-label="Share on Facebook"
                    role="menuitem"
                    round
                  />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <TwitterIcon
                    className="cursor-pointer my-2 w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 hover:translate-y-1"
                    id="twitterIcon"
                    aria-label="Share on Twitter"
                    role="menuitem"
                    round
                  />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href}>
                  <WhatsappIcon
                    className="cursor-pointer my-2 w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 hover:translate-y-1"
                    id="whatsAppIcon"
                    aria-label="Share on WhatsApp"
                    role="menuitem"
                    round
                  />
                </WhatsappShareButton>
                <EmailShareButton url={window.location.href}>
                  <EmailIcon
                    className="cursor-pointer my-2 w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 hover:translate-y-1"
                    id="emailIcon"
                    aria-label="Share via Email"
                    role="menuitem"
                    round
                  />
                </EmailShareButton>
                <TbCopy
                  id="navCopyIcon"
                  className="cursor-pointer my-2 w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 hover:translate-y-1"
                  onClick={handleCopyLink}
                  aria-label="Copy link to clipboard"
                  role="menuitem"
                />
              </div>
            </Suspense>
          </Dropdown>
        </div>

        {/* Listing and Home Buttons */}
        <Link
          to="/listings"
          state={{ forceRefresh: true }}
          className="w-8 h-8 flex items-center justify-center rounded-full bg-transparent text-purple-400 transition duration-300 ease-in-out hover:-translate-y-1"
          aria-label="Go to Search Listings"
        >
          <TbHomeSearch className="!stroke-[1.5px] cursor-pointer w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-purple-600 dark:text-purple-200 hover:text-purple-300 dark:hover:text-purple-400 transition ease-in-out duration-150" />
        </Link>

        {/* Hamburger Icon for Mobile Menu */}
        <div className="md:!hidden flex items-center">
          <button
            className="hamburger-btn"
            onClick={toggleMobileMenu}
            aria-label="Open mobile menu"
          >
            <CgMenuLeftAlt className="hamburger-icon w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 dark:text-purple-300" />
          </button>
        </div>
      </div>

      {/* Center Section: Main Navigation Buttons */}
      <div className="hidden md:!flex max-[840px]:!space-x-0 justify-center flex-1 space-x-4 md:space-x-3 lg:space-x-6">
        {/* Contact Dropdown */}
        <div className="relative">
          <button
            onClick={toggleContactDropdown}
            className="flex items-center px-3 md:px-2 lg:px-4 py-2 text-sm md:text-md lg:text-lg font-extraBold tracking-tighter text-slate-700 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-purple-800/50 rounded-md transition-all duration-300"
            aria-expanded={activeDropdown === "contact"}
            aria-haspopup="true"
            aria-controls={`contact-dropdown`}
            aria-label="Open Contact menu"
          >
            Contact
            <RiArrowDropDownLine
              className={`h-6 w-6 transition-transform duration-300 ${activeDropdown === "contact" ? "rotate-180" : "rotate-0"
                }`}
              aria-hidden="true"
            />
          </button>
          {activeDropdown === "contact" && (
            <ul
              className="absolute top-full left-0 mt-1 w-56 py-2 bg-white shadow-lg rounded-md dark:bg-slate-700 z-50"
              id={`contact-dropdown`}
              role="menu"
              aria-labelledby={`button-contact`}
            >
              <ContactDropdown />
            </ul>
          )}
        </div>

        {/* Buy Dropdown */}
        <div className="relative">
          <button
            onClick={toggleBuyDropdown}
            className="flex items-center px-3 md:px-2 lg:px-4 py-2 text-sm md:text-md lg:text-lg font-extraBold tracking-tighter text-slate-700 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-purple-800/50 rounded-md transition-all duration-300"
            aria-expanded={activeDropdown === "buy"}
            aria-haspopup="true"
            aria-controls={`buy-dropdown`}
            aria-label="Open Buy menu"
          >
            Buy
            <RiArrowDropDownLine
              className={`h-6 w-6 transition-transform duration-300 ${activeDropdown === "buy" ? "rotate-180" : "rotate-0"
                }`}
              aria-hidden="true"
            />
          </button>
          {activeDropdown === "buy" && (
            <ul
              className="absolute top-full left-0 mt-1 w-56 py-2 bg-white shadow-lg rounded-md dark:bg-slate-700 z-50"
              id={`buy-dropdown`}
              role="menu"
              aria-labelledby={`button-buy`}
            >
              <BuyDropdown />
            </ul>
          )}
        </div>

        {/* Sell Dropdown */}
        <div className="relative">
          <button
            onClick={toggleSellDropdown}
            className="flex items-center px-3 md:px-2 lg:px-4 py-2 text-sm md:text-md lg:text-lg font-extraBold tracking-tighter text-slate-700 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-purple-800/50 rounded-md transition-all duration-300"
            aria-expanded={activeDropdown === "sell"}
            aria-haspopup="true"
            aria-controls={`sell-dropdown`}
            aria-label="Open Sell menu"
          >
            Sell
            <RiArrowDropDownLine
              className={`h-6 w-6 transition-transform duration-300 ${activeDropdown === "sell" ? "rotate-180" : "rotate-0"
                }`}
              aria-hidden="true"
            />
          </button>
          {activeDropdown === "sell" && (
            <ul
              className="absolute top-full left-0 mt-1 w-56 py-2 bg-white shadow-lg rounded-md dark:bg-slate-700 z-50"
              id={`sell-dropdown`}
              role="menu"
              aria-labelledby={`button-sell`}
            >
              <SellDropdown />
            </ul>
          )}
        </div>

        {/* Invest Dropdown */}
        <div className="relative">
          <button
            onClick={toggleInvestDropdown}
            className="flex items-center px-3 md:px-2 lg:px-4 py-2 text-sm md:text-md lg:text-lg font-extraBold tracking-tighter text-slate-700 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-purple-800/50 rounded-md transition-all duration-300"
            aria-expanded={activeDropdown === "invest"}
            aria-haspopup="true"
            aria-controls={`invest-dropdown`}
            aria-label="Open Invest menu"
          >
            Invest
            <RiArrowDropDownLine
              className={`h-6 w-6 transition-transform duration-300 ${activeDropdown === "invest" ? "rotate-180" : "rotate-0"
                }`}
              aria-hidden="true"
            />
          </button>
          {activeDropdown === "invest" && (
            <ul
              className="absolute top-full left-0 mt-1 w-56 py-2 bg-white shadow-lg rounded-md dark:bg-slate-700 z-50"
              id={`invest-dropdown`}
              role="menu"
              aria-labelledby={`button-invest`}
            >
              <InvestDropdown />
            </ul>
          )}
        </div>

        {/* Rent Dropdown */}
        <div className="relative">
          <button
            onClick={toggleRentDropdown}
            className="flex items-center px-3 md:px-2 lg:px-4 py-2 text-sm md:text-md lg:text-lg font-extraBold tracking-tighter text-slate-700 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-purple-800/50 rounded-md transition-all duration-300"
            aria-expanded={activeDropdown === "rent"}
            aria-haspopup="true"
            aria-controls={`rent-dropdown`}
            aria-label="Open Rent menu"
          >
            Rent
            <RiArrowDropDownLine
              className={`h-6 w-6 transition-transform duration-300 ${activeDropdown === "rent" ? "rotate-180" : "rotate-0"
                }`}
              aria-hidden="true"
            />
          </button>
          {activeDropdown === "rent" && (
            <ul
              className="absolute top-full left-0 mt-1 w-56 py-2 bg-white shadow-lg rounded-md dark:bg-slate-700 z-50"
              id={`rent-dropdown`}
              role="menu"
              aria-labelledby={`button-rent`}
            >
              <RentDropdown />
            </ul>
          )}
        </div>

        {/* Financing Dropdown */}
        <div className="relative">
          <button
            onClick={toggleFinanceDropdown}
            className="flex items-center px-3 md:px-2 lg:px-4 py-2 text-sm md:text-md lg:text-lg font-extraBold tracking-tighter text-slate-700 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-purple-800/50 rounded-md transition-all duration-300"
            aria-expanded={activeDropdown === "finance"}
            aria-haspopup="true"
            aria-controls={`finance-dropdown`}
            aria-label="Financing menu" // Include the visible text here
          >
            Financing

            <RiArrowDropDownLine
              className={`h-6 w-6 transition-transform duration-300 ${activeDropdown === "finance" ? "rotate-180" : "rotate-0"
                }`}
              aria-hidden="true"
            />
          </button>
          {activeDropdown === "finance" && (
            <ul
              className="absolute top-full left-0 mt-1 w-56 py-2 bg-white shadow-lg rounded-md dark:bg-slate-700 z-50"
              id={`finance-dropdown`}
              role="menu"
              aria-labelledby={`button-finance`}
            >
              <FinancingDropdown />
            </ul>
          )}
        </div>
      </div>

      {/* Right Section */}
      <div className="flex items-center justify-end flex-none space-x-2">
        {/* Optional Right Section Elements */}
        {/* Example: Logo or Additional Icons */}
        <MemoizedLogo />
      </div>

      {/* Mobile Navigation */}
      <div
        className={`md:!hidden fixed inset-0 z-50 overflow-hidden transition-transform duration-300 ease-in-out ${isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
      >
        <div className="absolute inset-0 bg-white w-[96vw] h-[98vh] shadow-xl rounded-lg transition-transform duration-300 ease-in-out translate-x-0 z-[99999]">
          <nav className="flex h-full no-scrollbar touch-smooth scroll-smooth flex-col p-6 bg-white rounded-lg shadow-2xl overflow-y-auto">
            {/* Mobile Menu Header */}
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Menu</h2>
              <button
                type="button"
                className="rounded-full text-gray-400 hover:text-gray-600"
                onClick={closeMobileMenu}
                aria-label="Close menu"
              >
                <MdOutlineCancel className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Mobile Dropdown Menus */}
            <ul className="space-y-4">
              {/* Contact Dropdown */}
              <li className="text-left">
                <div role="group">
                  <button
                    onClick={() => toggleDropdown("contact")}
                    className="text-lg font-medium text-gray-700 w-full flex items-center justify-between px-2 py-3 transition-all duration-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-200 dark:hover:text-gray-900"
                    aria-expanded={activeDropdown === "contact"}
                    aria-controls={`contact-dropdown-mobile`}
                    aria-label="Open Contact mobile menu"
                  >
                    Contact
                    <RiArrowDropDownLine
                      className={`transition-transform duration-500 text-purple-500 hover:text-indigo-500 h-6 w-6 scale-150 ${activeDropdown === "contact" ? "rotate-180" : "rotate-0"
                        }`}
                      aria-hidden="true"
                    />
                  </button>
                  {activeDropdown === "contact" && (
                    <ul
                      role="region"
                      id={`contact-dropdown-mobile`}
                      aria-labelledby={`button-contact`}
                    >
                      <ContactDropdown closeMobileMenu={closeMobileMenu} />
                    </ul>
                  )}
                </div>
              </li>

              {/* Buy Dropdown */}
              <li className="text-left">
                <div role="group">
                  <button
                    onClick={() => toggleDropdown("buy")}
                    className="text-lg font-medium text-gray-700 w-full flex items-center justify-between px-2 py-3 transition-all duration-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-200 dark:hover:text-gray-900"
                    aria-expanded={activeDropdown === "buy"}
                    aria-controls={`buy-dropdown-mobile`}
                    aria-label="Open Buy mobile menu"
                  >
                    Buy
                    <RiArrowDropDownLine
                      className={`transition-transform duration-500 text-purple-500 hover:text-indigo-500 h-6 w-6 scale-150 ${activeDropdown === "buy" ? "rotate-180" : "rotate-0"
                        }`}
                      aria-hidden="true"
                    />
                  </button>
                  {activeDropdown === "buy" && (
                    <ul
                      role="region"
                      id={`buy-dropdown-mobile`}
                      aria-labelledby={`button-buy`}
                    >
                      <BuyDropdown closeMobileMenu={closeMobileMenu} />
                    </ul>
                  )}
                </div>
              </li>

              {/* Sell Dropdown */}
              <li className="text-left">
                <div role="group">
                  <button
                    onClick={() => toggleDropdown("sell")}
                    className="text-lg font-medium text-gray-700 w-full flex items-center justify-between px-2 py-3 transition-all duration-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-200 dark:hover:text-gray-900"
                    aria-expanded={activeDropdown === "sell"}
                    aria-controls={`sell-dropdown-mobile`}
                    aria-label="Open Sell mobile menu"
                  >
                    Sell
                    <RiArrowDropDownLine
                      className={`transition-transform duration-500 text-purple-500 hover:text-indigo-500 h-6 w-6 scale-150 ${activeDropdown === "sell" ? "rotate-180" : "rotate-0"
                        }`}
                      aria-hidden="true"
                    />
                  </button>
                  {activeDropdown === "sell" && (
                    <ul
                      role="region"
                      id={`sell-dropdown-mobile`}
                      aria-labelledby={`button-sell`}
                    >
                      <SellDropdown closeMobileMenu={closeMobileMenu} />
                    </ul>
                  )}
                </div>
              </li>

              {/* Invest Dropdown */}
              <li className="text-left">
                <div role="group">
                  <button
                    onClick={() => toggleDropdown("invest")}
                    className="text-lg font-medium text-gray-700 w-full flex items-center justify-between px-2 py-3 transition-all duration-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-200 dark:hover:text-gray-900"
                    aria-expanded={activeDropdown === "invest"}
                    aria-controls={`invest-dropdown-mobile`}
                    aria-label="Open Invest mobile menu"
                  >
                    Invest
                    <RiArrowDropDownLine
                      className={`transition-transform duration-500 text-purple-500 hover:text-indigo-500 h-6 w-6 scale-150 ${activeDropdown === "invest" ? "rotate-180" : "rotate-0"
                        }`}
                      aria-hidden="true"
                    />
                  </button>
                  {activeDropdown === "invest" && (
                    <ul
                      role="region"
                      id={`invest-dropdown-mobile`}
                      aria-labelledby={`button-invest`}
                    >
                      <InvestDropdown closeMobileMenu={closeMobileMenu} />
                    </ul>
                  )}
                </div>
              </li>

              {/* Rent Dropdown */}
              <li className="text-left">
                <div role="group">
                  <button
                    onClick={() => toggleDropdown("rent")}
                    className="text-lg font-medium text-gray-700 w-full flex items-center justify-between px-2 py-3 transition-all duration-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-200 dark:hover:text-gray-900"
                    aria-expanded={activeDropdown === "rent"}
                    aria-controls={`rent-dropdown-mobile`}
                    aria-label="Open Rent mobile menu"
                  >
                    Rent
                    <RiArrowDropDownLine
                      className={`transition-transform duration-500 text-purple-500 hover:text-indigo-500 h-6 w-6 scale-150 ${activeDropdown === "rent" ? "rotate-180" : "rotate-0"
                        }`}
                      aria-hidden="true"
                    />
                  </button>
                  {activeDropdown === "rent" && (
                    <ul
                      role="region"
                      id={`rent-dropdown-mobile`}
                      aria-labelledby={`button-rent`}
                    >
                      <RentDropdown closeMobileMenu={closeMobileMenu} />
                    </ul>
                  )}
                </div>
              </li>

              {/* Finance Dropdown */}
              <li className="text-left">
                <div role="group">
                  <button
                    onClick={() => toggleDropdown("finance")}
                    className="text-lg font-medium text-gray-700 w-full flex items-center justify-between px-2 py-3 transition-all duration-200 rounded-md hover:bg-gray-100 dark:hover:bg-gray-200 dark:hover:text-gray-900"
                    aria-expanded={activeDropdown === "finance"}
                    aria-controls={`finance-dropdown-mobile`}
                    aria-label="Open Finance mobile menu"
                  >
                    Finance
                    <RiArrowDropDownLine
                      className={`transition-transform duration-500 text-purple-500 hover:text-indigo-500 h-6 w-6 scale-150 ${activeDropdown === "finance" ? "rotate-180" : "rotate-0"
                        }`}
                      aria-hidden="true"
                    />
                  </button>
                  {activeDropdown === "finance" && (
                    <ul
                      role="region"
                      id={`finance-dropdown-mobile`}
                      aria-labelledby={`button-finance`}
                    >
                      <FinancingDropdown closeMobileMenu={closeMobileMenu} />
                    </ul>
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
