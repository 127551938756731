import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiHome, FiMapPin, MdInfo, MdLibraryBooks, MdPhone, PiNotepadThin } from "../../assets/svgIcons/svgIcons";

export default function NotFound() {
    const navigate = useNavigate();


    return (
        <div
            className="
            relative w-full h-screen 
            flex items-center justify-center p-6 
            overflow-hidden font-league-spartan font-extralight
            bg-white text-gray-800 
            dark:bg-gradient-to-b dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 dark:text-white
          "
        >
            {/* Outer Pulsating Glow Effect (same as in GetMoving) */}
            <div className="absolute inset-0 rounded-2xl pointer-events-none animate-siriOuterGlow"></div>

            {/* Animated Gradient Border Wrapper */}
            <div
                className="
              relative w-full max-w-3xl rounded-2xl p-[3px] 
              bg-[length:400%_400%] animate-siriBorder 
              bg-[linear-gradient(90deg,#fbcfe8,#fef08a,#a5f3fc,#c4b5fd,#fbcfe8)]
              dark:bg-[linear-gradient(90deg,#ff0080,#ff8c00,#ff0080,#8a2be2,#1e90ff,#ff0080)]
              shadow-xl
            "
            >
                {/* Inner Content Container */}
                <div
                    className="
                bg-gradient-to-br from-gray-100 to-gray-200
                dark:from-gray-900 dark:via-black dark:to-gray-950
                opacity-95 rounded-2xl p-10 border
                border-gray-200 dark:border-gray-700
                shadow-[0px_0px_25px_rgba(255,255,255,0.15)]
                text-center
              "
                >
                    {/* 404 Header */}
                    <h1 className="text-6xl font-black text-red-500 dark:text-red-600">
                        404
                    </h1>
                    <h2 className="  text-3xl font-bold mt-4  bg-gradient-to-r from-pink-500 to-violet-500 text-transparent bg-clip-text  dark:from-pink-400 dark:to-violet-400">
                        This Page Is Off the Market
                    </h2>
                    <p className="mt-2 text-lg text-gray-600 dark:text-gray-300 max-w-lg mx-auto">
                        It seems the page you’re looking for doesn’t exist or was moved.
                        Let’s help you get back on track to finding your next home.
                    </p>

                    {/* Main Action Buttons */}
                    <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-center">

                        {/* Return Home Button */}
                        <div
                            className="
                    relative inline-flex items-center justify-center p-[2px] overflow-hidden 
                    font-extrabold tracking-wide uppercase rounded-xl
                    shadow-[0px_0px_8px_rgba(255,180,200,0.4)]
                    hover:shadow-[0px_0px_12px_rgba(255,180,200,0.6)]
                    transition-all duration-1000 hover:translate-y-[-1px] active:scale-[0.98]
                    bg-[length:400%_400%] animate-siriBorder
                    bg-[linear-gradient(90deg,#fbcfe8,#fef08a,#a5f3fc,#c4b5fd,#fbcfe8)]
                    dark:bg-[linear-gradient(90deg,#ff0080,#ff8c00,#ff0080,#8a2be2,#1e90ff,#ff0080)]
                  dark:hover:invert hover:transition-colors hover:duration-1000 hover:ease-in-out hover:ease-out"
                        >
                            <button
                                onClick={() => navigate("/")}
                                className="
                      relative px-6 py-3 
                      bg-white dark:bg-gray-900 
                      text-gray-900 dark:text-white 
                      rounded-xl
                      transition-colors duration-300 ease-in-out
                    "
                            >
                                <div className="flex items-center gap-2">
                                    <FiHome className="w-5 h-5" />
                                    <span className="text-base sm:text-lg">Return Home</span>
                                </div>
                            </button>
                        </div>

                        {/* Browse Listings Button */}
                        <div
                            className="
                    relative inline-flex items-center justify-center p-[2px] overflow-hidden 
                    font-extrabold tracking-wide uppercase rounded-xl
                    shadow-[0px_0px_8px_rgba(255,180,200,0.4)]
                    hover:shadow-[0px_0px_12px_rgba(255,180,200,0.6)]
                    transition-all duration-1000 hover:translate-y-[-1px] active:scale-[0.98]
                    bg-[length:400%_400%] animate-siriBorder
                    bg-[linear-gradient(90deg,#fbcfe8,#fef08a,#a5f3fc,#c4b5fd,#fbcfe8)]
                    dark:bg-[linear-gradient(90deg,#ff0080,#ff8c00,#ff0080,#8a2be2,#1e90ff,#ff0080)] dark:hover:invert hover:transition-colors hover:duration-1000 hover:ease-in-out"
                        >
                            <button
                                onClick={() => navigate("/listings")}
                                className="relative px-6 py-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-white rounded-xl transition-colors duration-300 ease-in-out"
                            >
                                <div className="flex items-center gap-2">
                                    <FiMapPin className="w-5 h-5" />
                                    <span className="text-base sm:text-lg">Browse Listings</span>
                                </div>
                            </button>
                        </div>

                    </div>

                    {/* Other Helpful Links */}
                    <div className="mt-10">
                        <p className="text-lg text-gray-600 dark:text-gray-300">
                            Need more info? Check out these pages:
                        </p>
                        <ul className="flex flex-wrap justify-center gap-8 mt-6 text-lg">

                            <li>
                                <a
                                    href="/about"
                                    className="inline-flex items-center gap-2 text-pink-500 dark:text-pink-400 hover:underline transition">
                                    <MdInfo className="w-6 h-6 text-current" />
                                    <span>About Us</span>
                                </a>
                            </li>

                            <li>
                                <a
                                    href="/contact"
                                    className="inline-flex items-center gap-2 text-orange-500 dark:text-orange-400 hover:underline transition">
                                    <MdPhone className="w-6 h-6 text-current" />
                                    <span>Contact</span>
                                </a>
                            </li>

                            <li>
                                <a
                                    href="/resources"
                                    className="inline-flex items-center gap-2 text-teal-500 dark:text-teal-400 hover:underline transition">
                                    <MdLibraryBooks className="w-6 h-6 text-current" />
                                    <span>Resources</span>
                                </a>
                            </li>

                            <li>
                                <a
                                    href="/blog"
                                    className="inline-flex items-center gap-2 text-violet-500 dark:text-violet-400 hover:underline transition">
                                    <PiNotepadThin className="w-6 h-6 text-current" />
                                    <span>Blog</span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    {/* Footer */}
                    <div className="mt-8 text-sm text-gray-400 dark:text-gray-500">
                        © {new Date().getFullYear()} GetMoving. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}